import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosResponse } from 'axios';

export type TransactionDetails = {
    id?: string,
    _id: string,
    bookingUId: string,
    serviceName: string,
    userName: string,
    createdAt: string,
    image: string;
    amount?:string;
}

export type TransactionData = {
    data: TransactionDetails
    count: number,
    totalCount: number,
    totalPages: number,
    page: number,
    totalLength: number
}

export const getTransactionList = (bookingId: string, service: string, date: string, status: string, page?: number): Promise<AxiosResponse<TransactionData>> => {
    if (bookingId || service || date || status) {
        return axios.get(`/admin/transactions-list?page=${page}&bookingId=${bookingId}&service=${service}&date=${date}&status=${status}`);
    }
    return axios.get(`/admin/transactions-list?page=${page ?? 1}`);
};

type QueryFnType = typeof getTransactionList;

type UseOptions = {
    config?: QueryConfig<QueryFnType>;
    page?: number;
    bookingId: string;
    service: string;
    date: string;
    status: string
};

export const useTransactionList = ({ config, page, bookingId, service, date, status }: UseOptions = {
    page: 1,
    bookingId: '',
    service: '',
    date: '',
    status: ''
}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['appointmentslist'],
        queryFn: () => getTransactionList(bookingId, service, date, status, page),
    });
};
