import { axios } from "@/lib/axios"
import { HelpQuery } from "./getHelpQueries"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export const getSingleQuery = (id: string): Promise<HelpQuery> => {
    return axios.get(`/admin/single-query/${id}`)
};

type QueryFnType = typeof getSingleQuery;

type useSingleValues = {
    config?: QueryConfig<QueryFnType>;
    id: string;
};

export const useSingleQuery = ({ config, id }: useSingleValues = { id: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['single-help-query'],
        queryFn: () => getSingleQuery(id)
    })
} 