import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import '../routes/auth.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { registerWithEmailAndPassword } from '../api/register';

const schema = z
  .object({
    name: z.string().min(1, 'Please enter first name'),
    email: z.string().min(1, 'Please enter work email address'),
    password: z.string().min(1, 'Please enter password'),
    confirm_password: z.string().min(1, 'Please enter confirm password'),
  })
  .superRefine(({ confirm_password, password }, ctx) => {
    if (confirm_password !== password) {
      ctx.addIssue({
        path: ['confirm_password'],
        code: 'custom',
        message: 'Confirm password should be same as password',
      });
    }
  });

type RegisterValues = {
  name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password?: string;
};

type RegisterFormProps = {
  onSuccess: (email: string) => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const [loading, setLoading] = useState(false);

  const register = async (values: RegisterValues) => {
    setLoading(true);
    try {
      delete values.confirm_password;
      await registerWithEmailAndPassword(values);
      onSuccess(values.email);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-3">
      <h5 className="text-center">Sign up</h5>
      <p className="text-center fs-8">Fill in your information and get started:</p>
      <Form<RegisterValues, typeof schema> onSubmit={register} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Name"
              error={formState.errors['name']}
              registration={register('name')}
            />

            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div className="d-flex gap-3">
              <div className="w-50">
                <InputField
                  type="password"
                  label="Password"
                  error={formState.errors['password']}
                  registration={register('password')}
                />
              </div>
              <div className="w-50">
                <InputField
                  type="password"
                  label="Confirm password"
                  error={formState.errors['confirm_password']}
                  registration={register('confirm_password')}
                />
              </div>
            </div>
            <div>
              <Button isLoading={loading} type="submit" className="w-full">
                Get Started
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-4">
        <p className="text-center light-text mb-0 fs-7">Already an user?</p>
        <p className="text-center light-text fs-7">
          <Link to="/auth/login"> Sign in </Link>
        </p>
      </div>
    </div>
  );
};
