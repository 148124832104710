import { Skeleton } from '@mui/material';
import React from 'react';

const LoaderForAll = () => {
  return (
    <>
      <div className="mx-4 mt-4">
        <Skeleton variant="rectangular" height={50}></Skeleton>
      </div>
      <div>
        <div className="mx-4  mt-2">
          <Skeleton variant="rectangular" height={50}></Skeleton>
        </div>
        <div className="mx-4  mt-2">
          {' '}
          <Skeleton variant="rectangular" height={50}></Skeleton>
        </div>
      </div>
    </>
  );
};

export default LoaderForAll;
