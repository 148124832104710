import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export const getAllChaperones = (searchKey?: string, page?: number): Promise<User[]> => {
  if (!searchKey) {
    return axios.get(`/admin/chaperones?page=${page}`);
  }
  const urlBuilder = new URLSearchParams();
  if (searchKey) {
    urlBuilder.set('searchKey', searchKey);
  }
  return axios.get(`/admin/chaperones?${urlBuilder.toString()}`);
};

type QueryFnType = typeof getAllChaperones;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
  searchKey?: string;
  page: number;
};

export const useAllChaperones = ({ config, searchKey, page }: UseOptions = { searchKey: '', page: 1 }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['chaperones'],
    queryFn: () => getAllChaperones(searchKey, page),
  });
};
