import { useEffect, useRef, useState } from 'react';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { useTermsCondition } from '@/api/conditions/getTerms';
import { addUpdateTerms } from '@/api/conditions/addUpdateTerm';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import './ContentManagement.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNotificationStore } from '@/stores/notifications';

type AddTerms = {
  title: string;
  text: string;
};

const schema = z.object({
  title: z.string().min(1, 'Please enter title'),
  text: z.string().optional(),
});
const stateFromHTML = require('draft-js-import-html').stateFromHTML;

export const TermCondition = () => {
  const [reload, setReload] = useState(false);
  const [postLoading, setPostLoading] = useState<boolean>(false);

  const { data, isLoading, isFetching } = useTermsCondition();


  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [html, setHtml] = useState<any>(null);

  useEffect(() => {
    if (data?.data) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [data?.data]);

  const handleChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setHtml(currentContentAsHTML);
  };

  const { addNotification } = useNotificationStore();

  const handleSubmit = async (values: AddTerms) => {
    setPostLoading(true);
    const payload: any = {
      text: html ?? '',
      title: values.title,
    };
    await addUpdateTerms({ data: payload });
    setPostLoading(false);
    addNotification({
      type: 'success',
      title: "Terms and Conditions added successfuly"
    })
  };

  function htmlDecode(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent ?? '';
  }
  useEffect(() => {
    setHtml(data?.data?.text);
    const contentState = stateFromHTML(htmlDecode(data?.data?.text));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [data?.data?.text]);

  return (
    <>
      {isLoading || isFetching ? <Spinner /> :
        <ContentLayout
          title="Terms And Conditions"
        >
          <div className="card shadow border-0 rounded add-user-form mt-4 terms">
            <p className="f-20 bold mb-0">Please enter the following details</p>
            <div className="shop-details">
              {!reload && (
                <Form<AddTerms, typeof schema>
                  onSubmit={handleSubmit}
                  schema={schema}
                  options={{
                    defaultValues: {
                      ...data?.data,
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <div className="row mt-4">
                        <div className="col-12">
                          <InputField
                            error={formState.errors['title']}
                            registration={register('title', {
                              value: data?.data?.title,
                            })}
                            type="text"
                            label="Title"
                            placeholder="Title"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <label>Text</label>

                        <div className="border text-editr">
                          <Editor editorState={editorState} onEditorStateChange={handleChange} />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <Button className="expanded-btn"
                            isLoading={postLoading}
                            type="submit">
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </div>
          </div>
        </ContentLayout>
      }
    </>
  );
};
