import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Service } from '@/types';

export const getAllService = (name?: string, type?: string, page?: number): Promise<Service[]> => {
  if (!name && !type) {
    return axios.get(`/admin/service?page=${page ?? 1}`);
  }
  const urlBuilder = new URLSearchParams();
  if (name) {
    urlBuilder.set('name', name);
  }
  if (type) {
    urlBuilder.set('type', type);
  }
  return axios.get(`/admin/service/search?${urlBuilder.toString()}`);
};

type QueryFnType = typeof getAllService;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
  name?: string;
  type?: string;
  page?: number;
};

export const useAllService = ({ config, name, type, page }: UseOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['services'],
    queryFn: () => getAllService(name, type, page),
  });
};
