import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';

import placeHolderProfile from '@/assets/placeholder.jpg';
import moment from 'moment';
import LoaderForAll from '@/components/Loader/LoaderForAll';
import { useUser } from '@/api/user/getUser';
import { BookingData, useAppointmentList } from '@/api/appointments/getAppointmentList';
import { usePagination } from '@/hooks/usePagination';
import { Button, Table } from '@/components/Elements';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import eye from '@/assets/eye.png';

export const ViewUser = () => {
  const { id } = useParams();
  const { page, changePage } = usePagination();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useUser({ id: id ?? '1' });
  const [toggleVal, setToggleVal] = useState<string>('upcoming');

  const {
    data: list,
    isLoading: loading,
    refetch: refetchList,
  } = useAppointmentList({ id: id, page: page, date: toggleVal });

  useEffect(() => {
    refetchList();
  }, [toggleVal]);

  useEffect(() => {
    refetch();
  }, []);


  const joinDate = moment(data?.data?.createdAt).format('DD MMMM YYYY');

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setToggleVal(value);
  };

  return (
    <div>
      {isLoading || loading ? (
        <LoaderForAll />
      ) : (
        <ContentLayout title="View User Details">
          <div className="card shadow border-0 rounded add-user-form space-card mt-4">
            <div className="row ">
              <div className="col-12 col-md-4 col-lg-3">
                <span className="driver-left">
                  <b>User Image </b>
                </span>
                <img
                  src={data?.data?.image ? data?.data?.image : placeHolderProfile}
                  className="user-images"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeHolderProfile;
                  }}
                />
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>User Name </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.name}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Email </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.email}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Phone Number </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.phoneNumber}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Since Joined </b>
                      </div>
                      <div className="driver-rghtt">{joinDate}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Status </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.status}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-5 bold">Appointments</h5>
          <div className="row mt-3">
            <div className="col-8 col-md-8 user-appoint-r">
              <ToggleButtonGroup
                color="primary"
                value={toggleVal}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth={true}
              >
                <ToggleButton value="upcoming">Upcoming Appointments</ToggleButton>
                <ToggleButton value="past">Past Appointments</ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/* <div className="col-4 col-md-4 d-flex justify-content-end align-items-center">
              <Button
                variant='outline'
                onClick={() => setToggleVal('')}
              >
                All Appointments
              </Button>
            </div> */}
          </div>
          <div className="bg-white rounded mt-4">
            <div className="row p-4">
              <div className="col-md-6">
                <h5 className="bold f-22">Appointments List</h5>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
            </div>

            <div className="user-table mt-3">
              <Table<BookingData>
                page={page}
                changePage={changePage}
                totalPages={list?.totalPages}
                data={list?.data ?? []}
                columns={[
                  { title: 'Booking ID', field: 'bookingUId' },
                  { title: 'Services', field: 'serviceName' },
                  {
                    title: 'Date',
                    field: 'date',
                  },
                  { title: 'Time', field: 'startTime' },
                  { title: 'Chaperone Name', field: 'chaperoneName' },
                  {
                    title: 'Action',
                    field: '_id',
                    Cell({ entry: { _id } }) {
                      return (
                        <>
                          <Button
                            tabIndex={-1}
                            onClick={() => navigate(`/appointment/view/${_id}`)}
                            className="icon me-2"
                            variant="outline"
                            title="View"
                          >
                            <img src={eye} className='h-4' alt="" />
                          </Button>
                        </>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </ContentLayout>
      )}
    </div>
  );
};
