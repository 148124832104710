import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Service } from '@/types';

const queryName = 'services';

export type UpdateServiceDTO = {
  data: {
    service: string;
    description: string;
    serviceType: string;
  };
  id: string;
};

export const updateService = ({ data, id }: UpdateServiceDTO): Promise<Service> => {
  return axios.put(`/admin/service/${id}`, data);
};

type UseUpdateServiceOptions = {
  config?: MutationConfig<typeof updateService>;
};

export const useUpdateService = ({ config }: UseUpdateServiceOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async (updatingService: any) => {
      await queryClient.cancelQueries([queryName, updatingService?.id]);

      const previousService = queryClient.getQueryData<Service>([queryName, updatingService?.id]);

      queryClient.setQueryData([queryName, updatingService?.id], {
        ...previousService,
        ...updatingService.data,
        id: updatingService.id,
      });

      return { previousService };
    },
    onError: (_, __, context: any) => {
      if (context?.previousService) {
        queryClient.setQueryData([queryName, context.previousService.id], context.previousService);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries([queryName, data.id]);
      addNotification({
        type: 'success',
        title: 'Service Updated',
      });
      navigate('/service');
    },
    ...config,
    mutationFn: updateService,
  });
};
