import { addCommission } from '@/api/commision';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form'
import { ContentLayout } from '@/components/Layout'
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';
import { useState } from 'react';
import * as z from 'zod';

const schema = z.object({
    commission: z.string().min(1, 'Please enter commission')
        .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
        .regex(/^(?:[0-9]\d{0,1}|0)$/, 'Commission should not exceed than 2 digits'),
})

export type Commision = {
    commission: string;
}

const GeneralSetting = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { addNotification } = useNotificationStore();
    const {user} = useAuth();
    return (
        <>
            <ContentLayout title=''>
                <div className="container">
                    <div className="row">
                        <div className="col-6md-6">
                            <h3 className='font-bold'>General Settings</h3>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg mt-3 p-4">
                        <div className="border border-grey-500 rounded-lg p-4 mt-5">
                            <h6 className='font-bold'>Commission</h6>

                            <Form<Commision, typeof schema>
                                schema={schema}
                                onSubmit={async (values) => {
                                    try {
                                        setLoading(true);
                                        await addCommission(values);
                                        addNotification({
                                            type: 'success',
                                            title: 'Commission added sucessfuly'
                                        });
                                        setLoading(false);
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                                options={{
                                    defaultValues: {
                                        commission: user?.commission ?? ''
                                    }
                                }}
                            >
                                {({ register, formState }) => (
                                    <>
                                        {console.log(formState.errors)}
                                        <div className="row mt-5">
                                            <div className="col-md-6">
                                                <InputField
                                                    type='text'
                                                    label='Admin Commission (in %)'
                                                    placeholder='10%'
                                                    error={formState.errors['commission']}
                                                    registration={register('commission')}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-100 d-flex justify-content-end'>
                                            <Button
                                                type='submit'
                                                variant='primary'
                                                isLoading={loading}
                                                style={{ padding: '5px 60px', marginRight: '20px' }}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </div>
                    </div>
                </div>

            </ContentLayout>
        </>
    )
}

export default GeneralSetting