import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { upperFirst } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { activeInactive } from '@/api/service/service';
import { useDeleteService } from '@/api/service/deleteService';
import { useAllService } from '@/api/service/getServices';
import { Table, Button, ConfirmationDialog } from '@/components/Elements';
import { SelectField, Form } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
// import categorytype from '@/constants/categorytype';
import { usePagination } from '@/hooks/usePagination';
import { Service } from '@/types';
import placeHolderProfile from '@/assets/placeholder.jpg';
import eye from '@/assets/eye.png';
import edit from '@/assets/edit.png';
import bucket from '@/assets/deleteIcon.png';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const schema = z.object({
  serviceType: z.string().min(1, 'Service type is required'),
});

type LoginValues = {
  serviceType: any;
};

export const ServiceList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('');
  const { page, changePage } = usePagination();

  const { data, refetch } = useAllService({
    config: {
      staleTime: 60000,
    },
    name: searchTerm,
    type: searchType,
    page: page,
  });

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  console.log(page, 'page');
  const [isDeleting, setIsdeleting] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleClickOpen = async (id: any, activeStatus: string) => {
    await activeInactive(id, activeStatus);
    setSearchTerm('');
    refetch();
  };

  const [innerTab, setInnerTab] = useState(0);

  useEffect(() => {
    setInnerTab(innerTab);
  }, [innerTab]);

  const deleteServiceMutation = useDeleteService();

  const handleDelete = async (id: string) => {
    setIsdeleting(true);

    try {
      await deleteServiceMutation.mutateAsync({ id: id });
      setIsdeleting(false);
    } catch (error) {
      setIsdeleting(false);
    }
  };

  const handleSearch = async (search: any) => {
    if (search.length > 0) {
      setSearchTerm(search);
      setImmediate(() => refetch());
    } else {
      setSearchTerm('');
      setImmediate(() => refetch());
    }
  };

  const handleSearchType = async (search: any) => {
    if (search.length > 0) {
      setSearchType(search);
      setImmediate(() => refetch());
    } else {
      setSearchType('');
      setImmediate(() => refetch());
    }
  };

  console.log(data, 'data');

  return (
    <>
      <ContentLayout title="">
        <div className="my-4">
          <div className="main-content bg-white rounded">
            <div className="row p-4">
              <div className="col-md-6">
                <h4 className="f-20 bold">Service </h4>
                <p className="f-14 mb-1">You can view all your services here</p>
              </div>
              <div className="col-md-6">
                <div className="flex justify-end items-center gap-3">
                  <div className="">
                    <Button className="expanded-btn" onClick={() => navigate('/service/add')}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  mx-3">
              <div className="col-12 col-md-6">
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search"
                  className="search top-search"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  // sx={{ width: 600 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="user-table mt-4">
              <Table<Service>
                page={page}
                changePage={changePage}
                totalPages={data?.totalPages}
                total={data?.count ?? 0}
                data={data?.data ?? []}
                columns={[
                  {
                    title: 'Service Name',
                    field: 'serviceName',
                    Cell({ entry: { serviceName, image } }) {
                      return  (
                        <>
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              src={image ? image : placeHolderProfile}
                              style={{
                                width: '35px',
                                height: '35px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                                position: "relative",
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeHolderProfile;
                              }}
                              alt=""
                            />
                            <span style={{
                              position: 'relative',

                            }}
                            >{upperFirst(serviceName)}</span>
                          </div>
                        </>

                      );
                    },
                  },
                  {
                    title: 'Description',
                    field: 'description',
                    Cell({ entry: { description } }) {
                      return <span>{upperFirst(description)}</span>;
                    },
                  },
                  {
                    title: 'Price',
                    field: 'price',
                    Cell({ entry: { price } }) {
                      return <span>{`$${price}`}</span>;
                    },
                  },
                  {
                    title: 'Action',
                    field: '_id',
                    Cell({ entry: { _id, status } }) {
                      return (
                        <>
                          <Button
                            tabIndex={-1}
                            onClick={() => navigate(`/service/edit/${_id}`)}
                            className="icon me-2"
                            variant="outline"
                            title="Edit"
                          >
                            <img src={edit} className="h-5" alt="" />
                          </Button>

                          <ConfirmationDialog
                            triggerButton={
                              <Button
                                tabIndex={-1}
                                className="icon me-2"
                                variant="outline"
                                title="Delete"
                              >
                                <img src={bucket} className="h-5" alt="" />
                              </Button>
                            }
                            confirmButton={
                              <Button
                                isLoading={isDeleting}
                                onClick={() => handleDelete(_id)}
                                variant="primary"
                                className="expanded-btn"
                              >
                                Delete
                              </Button>
                            }
                            title="Delete Service"
                            icon="danger"
                            body="Are you sure you want to delete this service ?"
                          />
                          {/* {status === 'inactive' ? (
                            <ConfirmationDialog
                              triggerButton={
                                <Button
                                  tabIndex={-1}
                                  // onKeyDown={() => handleClickOpen(_id)}
                                  onClick={() => handleClickOpen(_id, status)}
                                  className="icon me-2"
                                  title="Active"
                                  variant="outline"
                                >
                                  <i className="fa-solid fa-check"></i>{' '}
                                </Button>
                              }
                              confirmButton={
                                <Button
                                  isLoading={isDeleting}
                                  onClick={() => handleClickOpen(_id, 'active')}
                                  variant="primary"
                                >
                                  Active
                                </Button>
                              }
                              title="Active Service"
                              icon="danger"
                              body="Are you sure you want to active this service ?"
                            />
                          ) : (
                            <ConfirmationDialog
                              triggerButton={
                                <Button
                                  tabIndex={-1}
                                  // onKeyDown={() => handleClickOpen(_id)}
                                  onClick={() => handleClickOpen(_id, status)}
                                  className="icon me-2"
                                  title="Inactive"
                                  variant="outline"
                                >
                                  <i className="fa-solid fa-ban"></i>{' '}
                                </Button>
                              }
                              confirmButton={
                                <Button
                                  isLoading={isDeleting}
                                  onClick={() => handleClickOpen(_id, 'inactive')}
                                  variant="primary"
                                  className="expanded-btn"
                                >
                                  Inactive
                                </Button>
                              }
                              title="Inactive Service"
                              icon="danger"
                              body="Are you sure you want to Inactive this service ?"
                            />
                          )} */}
                        </>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
