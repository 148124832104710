import React, { useState } from 'react';

import { getVideoCover } from '@/utils/getVideoCover';

export const useSelectFile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPicturePreview] = useState<string>('');
  const [cover, setCover] = useState<string>();
  const [pdfName, setpdfName] = useState<string>();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPicturePreview(URL.createObjectURL(file));
      setFile(e.target.files[0]);

      if (isVideo(file.name)) {
        const cov = await getVideoCover(file, 1.5);
        setCover(cov);
      }
      if (isPdf(file.name)) {
        const pdfName = file.name;
        setpdfName(pdfName);
      } else {
        setpdfName('');
      }
    }
  };

  return { file, preview, handleChange, setPicturePreview, cover, pdfName, setFile };
};

export function getExtension(filename: string) {
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

export function isImage(filename: string) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

export function isVideo(filename: string) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
}

export function isPdf(filename: string) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'pdf':
      //etc
      return true;
  }
  return false;
}
