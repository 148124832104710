import { useEffect, useRef, useState } from 'react';
import * as z from 'zod';

import { useCreateService } from '@/api/service/addService';
import { useUpdateService } from '@/api/service/updateService';
import { uploadImage } from '@/api/uploadImage';
import plus from '@/assets/plus.png';
import { Button } from '@/components/Elements';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
// import categoryType from '@/constants/categorytype';
import { useSelectFile } from '@/hooks/useSelectFile';
import { Service } from '@/types';

type CreateServicey = {
  image: string;
  serviceName: string;
  price: string;
  description: string;
};

const schema = z.object({
  serviceName: z
    .string()
    .min(1, 'Please enter service name')
    .regex(/^[^\d]+$/, 'Service name cannot contain numbers'),

  price: z
    .string()
    .min(1, 'Please enter service price')
    .regex(/^(?!0$)(?:[1-9]\d*|0)$/, 'Please enter valid numbers only')
    .regex(/^(?:[1-9]\d{0,3}|0)$/, 'Price should not exceed than 4 digits'),
    description: z.string().min(1, 'Please enter description')
});

export const CreateService = ({ service }: { service?: Service }) => {
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (service) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [service]);

  const { mutate: updateService } = useUpdateService();
  const { mutate: addService, isLoading } = useCreateService();

  const { file, preview, handleChange } = useSelectFile();
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const changeClicked = () => {
    inputFileRef?.current?.click();
  };

  const handleSubmit = async (values: CreateServicey) => {
    try {
      let image = service?.image ?? '';
      if (file) {
        const response = await uploadImage({ file });
        image = response.url;
      } else {
        image = service?.image ?? '';
      }
      const payload: any = {
        image: image,
        serviceName: values.serviceName,
        price: values.price.toString(),
        description: values.description
      };

      if (service) {
        updateService({ data: payload, id: service?._id });
      } else {
        addService({ data: payload });
      }
    } catch {
      console.log('');
    }
  };

  return (
    <ContentLayout title={!service ? 'Add Service' : 'Edit Service'}>
      <div className="row">
        <div className="col-12 col-md-10">
          <div className="card shadow border-0 rounded add-user-form space-card mt-4">
            <p className="f-20 bold">Please enter the following details</p>
            <div className="my-4">
              {!reload && (
                <Form<CreateServicey, typeof schema>
                  onSubmit={handleSubmit}
                  schema={schema}
                  options={{
                    defaultValues: {
                      ...service,
                      price: service?.price.toString(),
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <div className="foto">
                        {preview ? (
                          <img
                            className="previewImage setImage"
                            src={preview}
                            alt="h"
                            onClick={changeClicked}
                            onKeyDown={changeClicked}
                            role="menu"
                            tabIndex={-1}
                          />
                        ) : (
                          <>
                            {service?.image ? (
                              <>
                                <img
                                  className="previewImage setImage"
                                  src={service?.image}
                                  alt="i"
                                  onClick={changeClicked}
                                  onKeyDown={changeClicked}
                                  role="menu"
                                  tabIndex={-1}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  className="previewImage setplusImage"
                                  src={plus}
                                  alt="i"
                                  onClick={changeClicked}
                                  onKeyDown={changeClicked}
                                  role="menu"
                                  tabIndex={-1}
                                />
                              </>
                            )}
                          </>
                        )}
                        <input
                          ref={inputFileRef}
                          onChange={handleChange}
                          type="file"
                          id="fileInput"
                          className="d-none"
                        />
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['serviceName']}
                            registration={register('serviceName', {
                              // value: service?.service,
                            })}
                            type="text"
                            label="Service name"
                            placeholder="Service Name"
                          />
                        </div>

                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['price']}
                            registration={register('price', {
                              // value: service?.price,
                            })}
                            type="text"
                            label="Service Price"
                            placeholder="Service Price"
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <TextAreaField
                          label='Description'
                          error={formState.errors['description']}
                          registration={register('description')}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-4 text-center">
                        <Button isLoading={isLoading} type="submit" className="expanded-btn">
                          {service ? 'Edit' : 'Add Service'}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
