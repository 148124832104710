import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosMain } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Service } from '@/types';

const queryName = 'services';
export type AddServiceDTO = {
  data: {
    service: string;
    description: string;
    serviceType: string;
  };
};

export const addService = ({ data }: AddServiceDTO): Promise<Service> => {
  return axiosMain.post(`/admin/add-service`, data);
};

type UseAddServiceOptions = {
  config?: MutationConfig<typeof addService>;
};

export const useCreateService = ({ config }: UseAddServiceOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async (newService) => {
      await queryClient.cancelQueries(queryName);

      const previousService = queryClient.getQueryData<Service[]>(queryName);
      const newData = [...[], newService.data];

      // const newData = [...(previousService || []), newService.data];
      queryClient.setQueryData(queryName, newData);

      return { previousService };
    },
    onError: (_, __, context: any) => {
      if (context?.previousService) {
        queryClient.setQueryData(queryName, context.previousService);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryName);
      addNotification({
        type: 'success',
        title: 'Service Created',
      });
      navigate('/service');
    },
    ...config,
    mutationFn: addService,
  });
};
