import { PrivacyValues, addPrivacy } from '@/api/privacy/addPrivacy';
import { useSinglePrivacyData } from '@/api/privacy/getSinglePrivacy';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { useNotificationStore } from '@/stores/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import './ContentManagement.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const schema = z.object({
    title: z.string().min(1, "Please enter title"),
})

const stateFromHTML = require('draft-js-import-html').stateFromHTML;
const AddPrivacyPolicy = () => {
    const { addNotification } = useNotificationStore();
    const [reload, setReload] = useState<boolean>(false);
    const [postLoad, setPostLoad] = useState<boolean>(false);


    const { data, isFetching, isLoading } = useSinglePrivacyData();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [html, setHtml] = useState<any>(null);

    const handleChange = (state: any) => {
        setEditorState(state);
        convertContentToHTML();
    };

    const convertContentToHTML = () => {
        const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setHtml(currentContentAsHTML);
    };

    useEffect(() => {
        if (data?.data) {
            setReload(true);
            setTimeout(() => setReload(false), 0);
        }
    }, [data?.data])

    function htmlDecode(input: string): string {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent ?? '';
    }
    useEffect(() => {
        setHtml(data?.data?.text);
        const contentState = stateFromHTML(htmlDecode(data?.data?.text));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }, [data?.data?.text]);
    return (
        <>
            {isFetching || isLoading ? <Spinner /> :
                <ContentLayout title='Add Privacy Policy'>
                    <div className="card shadow border-0 rounded add-user-form mt-4 terms">
                        <div>
                            {!reload &&
                                <Form<PrivacyValues, typeof schema>
                                    onSubmit={async (values) => {
                                        setPostLoad(true);
                                        const payload: any = {
                                            text: html ?? '',
                                            title: values.title,
                                        }
                                        await addPrivacy(payload);
                                        setPostLoad(false);
                                        addNotification({
                                            type: 'success',
                                            title: "Privacy Policy added successfuly"
                                        });
                                    }}
                                    schema={schema}
                                    options={{
                                        defaultValues: {
                                            ...data?.data
                                        }
                                    }}

                                >
                                    {({ register, formState }) => (
                                        <>
                                            {console.log(formState.errors)}
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <InputField
                                                        type="text"
                                                        label="Title"
                                                        error={formState.errors['title']}
                                                        registration={register('title')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-md-12">

                                                    <label>Text</label>
                                                    <div className="border text-editr">
                                                        <Editor editorState={editorState} onEditorStateChange={handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        isLoading={postLoad}
                                                    >
                                                        SAVE
                                                    </Button>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form>
                            }
                        </div>
                    </div>
                </ContentLayout>
            }
        </>
    )
}

export default AddPrivacyPolicy;