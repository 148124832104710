import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosResponse } from 'axios';
import { PrivacyValues } from './addPrivacy';


export const getSinglePrivacy = (): Promise<AxiosResponse<PrivacyValues>> => {
  return axios.get(`/users/get?type=privacyPolicy`);
};


type QueryFnType = typeof getSinglePrivacy;

type UsePrivacyData = {
    config?: QueryConfig<QueryFnType>;
};

export const useSinglePrivacyData = ({ config }: UsePrivacyData = {} ) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['singlePrivacy'],
    queryFn: () => getSinglePrivacy(),
  });
};
