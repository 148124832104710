import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { User } from '@/types';

export type UpdateAdminDTO = {
  data: {
    name: string;
    phoneNumber: string;
    email: string;
  };
  id: string;
};

export const updateAdmin = ({ data }: UpdateAdminDTO): Promise<User> => {
  return axios.put(`/admin/update`, data);
};

type UseUpdateUserOptions = {
  config?: MutationConfig<typeof updateAdmin>;
};

export const useUpdateAdmin = ({ config }: UseUpdateUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingUser: any) => {
      await queryClient.cancelQueries(['users', updatingUser?.id]);

      const previousUser = queryClient.getQueryData<User>(['users', updatingUser?.id]);

      queryClient.setQueryData(['users', updatingUser?.id], {
        ...previousUser,
        ...updatingUser.data,
        id: updatingUser.id,
      });

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(['users', context.previousUser.id], context.previousUser);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['dashborad', data.id]);
      addNotification({
        type: 'success',
        title: 'Admin profile updated',
      });
    },
    ...config,
    mutationFn: updateAdmin,
  });
};
