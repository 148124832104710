import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosResponse } from 'axios';

export  type PayoutsDTO = {
    id?: string;
    _id: string | undefined;
    chaperone: string [];
    status: string;
    amount: number;
    createdAt: string;
  };

export type PayoutsData = {
    data: PayoutsDTO
    count: number,
    totalCount: number,
    totalPages: number,
    page: number,
    totalLength: number
}

export const getPayoutList = (chaperone: string, date: string, status: string, page?: number): Promise<AxiosResponse<PayoutsData>> => {
    if (chaperone || date || status) {
        return axios.get(`/admin/payouts-list?page=${page}&chaperone=${chaperone}&date=${date}&status=${status}`);
    }
    return axios.get(`/admin/payouts-list?page=${page ?? 1}`);
};

type QueryFnType = typeof getPayoutList;

type UseOptions = {
    config?: QueryConfig<QueryFnType>;
    page?: number;
    chaperone: string;
    date: string;
    status: string
};

export const usePayoutList = ({ config, chaperone, date, status, page}: UseOptions = {
    page: 1,
    chaperone: '',
    date: '',
    status: ''
}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['payoutslist'],
        queryFn: () => getPayoutList(chaperone, date, status, page),
    });
};