import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Service } from '@/types';

export const getAllAppointments = (appointmentId: string, service: string, date: string, status: string , page?: number): Promise<Service[]> => {
  if (appointmentId || service || date || status ) {
    return axios.get(`/admin/appointments?page=${page}&appointmentId=${appointmentId}&service=${service}&date=${date}&status=${status}`);
  }
  return axios.get(`/admin/appointments?page=${page ?? 1}`);
};

type QueryFnType = typeof getAllAppointments;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
  page?: number;
  appointmentId: string;
  service: string;
  date: string;
  status: string
};

export const useAllAppointments = ({ config, appointmentId, service, date, status, page }: UseOptions = 
  { 
    page: 1, 
    appointmentId: '' ,
    service: '',
    date: '',
    status: ''
  }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['appointments'],
    queryFn: () => getAllAppointments(appointmentId, service, date, status, page),
  });
};
