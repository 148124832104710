import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Service } from '@/types';
import { AxiosResponse } from 'axios';

export type BookingData = {
    id?: string,
    _id: string,
    date: string,
    startTime: string,
    bookingUId: string,
    serviceName: string,
    chaperoneName: string,
}

export type AppointData = {
    data: BookingData[]
    count: number,
    totalCount: number,
    totalPages: number,
    page: number,
    totalLength: number
}

export const getAppointmentList = (id: string, page?: number, date?: string): Promise<AxiosResponse<AppointData>> => {
    return axios.get(`/admin/appointment-list?id=${id}&page=${page}&date=${date}`);

};

type QueryFnType = typeof getAppointmentList;

type UseOptions = {
    config?: QueryConfig<QueryFnType>;
    id: string,
    date?: string;
    page?: number;
};

export const useAppointmentList = ({ config, id, date, page }: UseOptions = {id: ''}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['appointmentslist'],
        queryFn: () => getAppointmentList(id, page, date),
    });
};
