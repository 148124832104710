import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { User } from '@/features/owner/routes/User/User';
import { CreateUser } from '@/features/owner/routes/User/AddUser';
import { EditUser } from '@/features/owner/routes/User/EditUser';
import { AdminProfile } from '@/features/owner/routes/User/AdminProfile';
import { ServiceList } from '@/features/owner/routes/Service/Service';
import { CreateService } from '@/features/owner/routes/Service/AddService';
import { EditService } from '@/features/owner/routes/Service/EditService';
import { ViewUser } from '@/features/owner/routes/User/ViewUser';
import { AppointmentList } from '@/features/owner/routes/Appointment/Appointment';
import { ViewAppointments } from '@/features/owner/routes/Appointment/ViewAppointments';
import { Chaperone } from '@/features/owner/routes/Chaperone/Chaperone';
import { CreateChaperone } from '@/features/owner/routes/Chaperone/AddChaperone';
import { EditChaperone } from '@/features/owner/routes/Chaperone/EditChaperone';
import { ViewChaperone } from '@/features/owner/routes/Chaperone/ViewChaperone';
import { TermCondition } from '@/features/owner/routes/TermCondition';
import Faq from '@/features/owner/routes/Faq';
import { AddFaq } from '@/features/owner/routes/AddFaq';
import { EditFaq } from '@/features/owner/routes/EditFaq';
import AddPrivacyPolicy from '@/features/owner/routes/AddPrivacyPolicy';
import Payment from '@/features/owner/routes/Payment';
import Password from '@/features/owner/routes/Password';
import  NotificationsData  from '@/features/owner/routes/Notifications';
import HelpSupport from '@/features/owner/routes/HelpSupport';
import GeneralSetting from '@/features/owner/routes/Settings/GeneralSetting';
import ViewFaq from '@/features/owner/routes/ViewFaq';
import { Payouts } from '@/features/owner/routes/Payouts';

const { Dashboard } = lazyImport(() => import('@/features/owner'), 'Dashboard');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'users', element: <Dashboard /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
      { path: 'user', element: <User /> },
      { path: 'user/add', element: <CreateUser /> },
      { path: 'user/edit/:id', element: <EditUser /> },
      { path: 'profile', element: <AdminProfile /> },
      { path: 'service', element: <ServiceList /> },
      { path: 'service/add', element: <CreateService /> },
      { path: 'service/edit/:id', element: <EditService /> },
      { path: 'user/view/:id', element: <ViewUser /> },
      { path: 'appointment', element: <AppointmentList /> },
      { path: 'appointment/view/:id', element: <ViewAppointments /> },
      { path: 'chaperone', element: <Chaperone /> },
      { path: 'chaperone/add', element: <CreateChaperone /> },
      { path: 'chaperone/edit/:id', element: <EditChaperone /> },
      { path: 'chaperone/view/:id', element: <ViewChaperone /> },
      { path: 'terms', element: <TermCondition /> },
      { path: 'faq', element: <Faq /> },
      { path: 'add-faq', element: <AddFaq /> },
      { path: 'edit-faq/:id', element: <EditFaq /> },
      { path: 'add-privacy-policy', element: <AddPrivacyPolicy /> },
      { path: 'payment', element: <Payment /> },
      { path: 'password', element: <Password /> },
      { path: 'notifications', element: <NotificationsData/>},
      {path: 'help-support', element: <HelpSupport/>},
      {path: 'general-settings', element: <GeneralSetting/>},
      { path: 'view-faq/:id', element: <ViewFaq/> },
      { path : 'payouts', element: <Payouts/>},
    ],
  },
];
