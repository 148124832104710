import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, InputPhone } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import '../routes/auth.css';

const schema = z.object({
  email: z.string().min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              blueLabel
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              blueLabel
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                startIcon={<i className="fa-solid fa-lock" />}
                isLoading={isLoggingIn}
                type="submit"
                className="w-full"
              >
                Log In
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
