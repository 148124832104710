import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useService } from '@/api/service/getService';
import { ContentLayout } from '@/components/Layout';

import { CreateService } from './AddService';

export const EditService = () => {
  const { id } = useParams();
  const { data, refetch } = useService({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <ContentLayout title="">
        <div>
          <CreateService service={data?.data} />
        </div>
      </ContentLayout>
    </div>
  );
};
