import { addfaq, editfaq } from "@/api/addfaq";
import { Button, Spinner } from "@/components/Elements";
import { Form, InputField, TextAreaField } from "@/components/Form";
import { ContentLayout } from "@/components/Layout";
import { useNotificationStore } from "@/stores/notifications";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as z from 'zod';

const schema = z.object({
    question: z.string().min(1, "Please enter question"),
    answer: z.string().min(1, "Plese enter answer")
})


export type FaqData = {
    question: string;
    answer: string;
}



export const AddFaq = () => {
    const { id } = useParams();
    const { addNotification } = useNotificationStore();

    const navigate = useNavigate();

    return (
        <>
            <ContentLayout title='Add FAQ'>
                <div className="card shadow border-0 rounded add-user-form mt-4 terms">
                    <div>
                        <Form<FaqData, typeof schema>
                            onSubmit={async (values) => {

                                await addfaq(values)
                                addNotification({
                                    type: 'success',
                                    title: 'FAQ Added Successfuly',
                                });
                                navigate('/faq')
                            }}
                            schema={schema}

                        >
                            {({ register, formState }) => (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <InputField
                                                type="text"
                                                label="FAQ Question"
                                                error={formState.errors['question']}
                                                registration={register('question')}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <TextAreaField
                                                label="FAQ Answer"
                                                className="h-24"
                                                error={formState.errors['answer']}
                                                registration={register('answer')}
                                            />

                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <Button
                                                type="submit"
                                            >
                                                SAVE
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </ContentLayout>
        </>
    )
}