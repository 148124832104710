import { Button, ConfirmationDialog, Drawer, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import LoaderForAll from '@/components/Loader/LoaderForAll';
import { usePagination } from '@/hooks/usePagination';
import placeHolderProfile from '@/assets/placeholder.jpg';
import { usePayoutList } from '@/api/payouts/getPayoutList';
import { approvePayout } from '@/api/payouts/approvePayout';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import moment from 'moment';

export type PayoutsDTO = {
  id?: string;
  _id: string | undefined;
  chaperone: string[];
  status: string;
  amount: number;
  createdAt: string;
  chaperoneName: string;
  chaperoneImage: string;
};

export const Payouts = () => {
  const { page, changePage } = usePagination();
  const { open, close, isOpen } = useDisclosure();
  const [chaperoneFilter, setChaperoneFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [applyLoad, setApplyLoad] = useState<boolean>(false);
  const [clearLoad, setClearLoad] = useState<boolean>(false);
  const [payLoader, setPayLoader] = useState<boolean>(false);

  const { data, isLoading, isFetching, refetch } = usePayoutList({
    page: page,
    chaperone: chaperoneFilter,
    date: dateFilter,
    status: statusFilter,
  });
  const [loading, setLoading] = useState(false);


  const handleApplyFiter = () => {
    setApplyLoad(true);
    refetch();
    setApplyLoad(false);
    setClearFilter(false);
    close();
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setChaperoneFilter('');
    setDateFilter('');
    setStatusFilter('');
    setClearLoad(false);
  };

  const confirmActionAccept = async (id) => {
    try {
      setLoading(true);
      await approvePayout(id, true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      refetch();
    }
  };
  const confirmActionReject = async (id) => {
    try {
      setLoading(true);
      await approvePayout(id, false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    refetch();
    close();
  }, [clearFilter])

  useEffect(() => {
    refetch();
  }, [page])

  return (
    <>
      {isLoading || isFetching || loading ? (
        <LoaderForAll />
      ) : (
        <ContentLayout title="">
          <div className="bg-white rounded">
            <div className="row p-4">
              <div className="col-6 md-6">
                <h5 className="bold f-22">Payouts</h5>
              </div>
              <div className="col-6 md-6 d-flex justify-content-end align-items-center">
                <Button
                  variant='primary'
                  className='px-4'
                  onClick={open}
                >
                  Filters
                </Button>
              </div>
            </div>
            <div className="payouts-tbl">
              <Table<PayoutsDTO>
                page={page}
                changePage={changePage}
                data={data?.data ?? []}
                total={data?.count}
                totalPages={data?.totalPages}
                columns={[
                  {
                    title: 'chaperone',
                    field: 'chaperone',
                    Cell({ entry: { chaperoneName, chaperoneImage } }) {
                      return (
                        <>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={chaperoneImage ? chaperoneImage : placeHolderProfile}
                              style={{
                                width: '35px',
                                height: '35px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                                position: 'relative',
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeHolderProfile;
                              }}
                              alt=""
                            />
                            <span>{chaperoneName}</span>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: 'Amount',
                    field: 'amount',
                    Cell({entry:{ amount }}) {

                      return `$${amount}`
                    }
                  },
                  {
                    title: 'date',
                    field: 'createdAt',
                    Cell({ entry: { createdAt } }) {
                        const newDate = moment(createdAt).format('DD MMM YYYY')
                        return newDate;
                      }
                  },
                  {
                    title: 'Status',
                    field: 'status',
                  },
                  {
                    title: 'Actions',
                    field: 'actions',
                    Cell({ entry: { _id, status } }) {
                      if (status !== 'pending') {
                        return null;
                      }
                      return (
                        <div className="flex space-x-2">
                          <ConfirmationDialog
                          triggerButton={
                            <Button
                              className="icon me-2"
                              variant="outline"
                              title="confirm"
                            >
                             <i className="fa-solid fa-check"></i>
                            </Button>
                          }
                          confirmButton={
                            <Button
                              variant='primary'
                              className='expanded-btn'
                              onClick={() => confirmActionAccept(_id)}
                            >
                              Accept
                            </Button>
                          }
                          title='Complete Payout'
                          icon='info'
                          body="Are you sure you want to accept this payout!"
                        />
                            <ConfirmationDialog
                          triggerButton={
                            <Button
                              className="icon me-2"
                              variant="outline"
                              title="reject"
                            >
                             <i className="fa-solid fa-xmark"></i>
                            </Button>
                          }
                          confirmButton={
                            <Button
                              variant='primary'
                              className='expanded-btn'
                              onClick={() => confirmActionReject(_id)}
                            >
                              Reject
                            </Button>
                          }
                          title='Cancel Payout'
                          icon='danger'
                          body="Are you sure you want to cancel this payout!"
                        />
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Drawer
            title='Filters'
            isOpen={isOpen}
            onClose={close}
            size='sm'
            renderFooter={() => (
              <>
                <div className="row">
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={applyLoad}
                      onClick={handleApplyFiter}
                    >
                      Apply
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={clearLoad}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </>
            )}
          >
            <>
              <div className="row">
                <div className="col-md-12">
                <input
                    type="text"
                    placeholder="Chaperone"
                    className="form-control"
                    value={chaperoneFilter}
                    onChange={(e) => setChaperoneFilter(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="date"
                    className="form-control"
                    style={{ paddingRight: '0.75rem' }}
                    placeholder="date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <select
                    name="service"
                    className="form-control mt-4"
                    value={statusFilter}
                    style={{ paddingRight: '0.75rem' }}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                     <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </>
          </Drawer>
        </ContentLayout>
      )}
    </>
  );
};
