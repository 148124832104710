import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { User } from '@/types';

export const deleteChaperone = ({ id }: { id: string }) => {
  return axios.delete(`/admin/chaperone/${id}`);
};

type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteChaperone>;
};
export const useDeleteChaperone = ({ config }: UseDeleteUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deleteUser) => {
      await queryClient.cancelQueries('chaperones');

      const previousUser = queryClient.getQueryData<User[]>('chaperones');

      const itemToBeRemoved = document.querySelector(
        `.common-table-body tr[data-table-id='${deleteUser.id}']`
      );

      const removeFromFrontend = () => {
        queryClient.setQueryData(
          'chaperones',
          previousUser?.filter((user) => user.id !== deleteUser.id)
        );
      };

      if (itemToBeRemoved) {
        itemToBeRemoved.classList.remove('animate__fadeInUp');
        itemToBeRemoved.classList.add('animate__fadeOutLeft');
        setTimeout(() => {
          removeFromFrontend();
        }, 300);
      } else {
        removeFromFrontend();
      }

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData('chaperones', context.previousUser);
      }
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries('chaperones');
      }, 200);
      addNotification({
        type: 'success',
        title: 'Chaperone Deleted',
      });
      queryClient.refetchQueries('chaperones');
    },
    ...config,
    mutationFn: deleteChaperone,
  });
};
