import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Service } from '@/types';

export const getService = (id: string): Promise<Service> => {
  return axios.get(`/admin/service/${id}`);
};

type QueryFnType = typeof getService;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useService = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-service'],
    queryFn: () => getService(id),
  });
};
