import * as React from 'react';

import logo from '@/assets/logo-white.svg';
import { Button, Link } from '@/components/Elements';
import { Head } from '@/components/Head';
import { useNavigate } from 'react-router-dom';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const RegisterLayout = ({ children, title }: LayoutProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen btn-primary flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="flex justify-between">
          <Link className="flex items-center text-white" to="/">
            <img className="h-24" width="200" src={logo} alt="Workflow" />
          </Link>
          <div>
            <Button onClick={() => navigate('/auth/login')} variant="white">Login</Button>
          </div>
        </div>
        <h3 className="mt-3 text-center text-3xl text-gray-900 text-white">
          Your first month’s on us
        </h3>
        <div className="w-50 mx-auto ">
          <p className="mt-3 fs-8 text-center text-gray-900 text-white">
            You’ll love how easy it is to run payroll and streamline HR tasks with Taylor. With a
            free no-risk trial, you’ll have plenty of time to make sure it’s the right fit.
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
        </div>
      </div>
    </>
  );
};
