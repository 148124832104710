import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';

import placeHolderProfile from '@/assets/placeholder.jpg';
import placeHolderNoPic from '@/assets/no_photo_placeholder.jpg';
import moment from 'moment';
import { concat, upperFirst } from 'lodash';
import LoaderForAll from '@/components/Loader/LoaderForAll';
import { useUser } from '@/api/user/getUser';
import { Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import { useChaperone } from '@/api/chaperone/getChaperone';

export const ViewChaperone = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useChaperone({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderForAll />
      ) : (
        <ContentLayout title="View Chaperone Details">
          <div className="card shadow border-0 rounded add-user-form mt-4 space-card">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="">
                  <span className="driver-left">
                    <b>Chaperone Image</b>
                  </span>
                  <img
                    src={data?.data?.image ? data?.data?.image : placeHolderProfile}
                    className="chaperone-icon"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = placeHolderProfile;
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className='row'>
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Customer Name</b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.name}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Email</b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.email}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Phone Number</b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.phoneNumber}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Services</b>
                      </div>
                      <div className="driver-rghtt">
                        {data?.data?.services?.map((value) => value.serviceName)?.join(', ')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentLayout>
      )}
    </div>
  );
};
