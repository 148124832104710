import React, { useEffect, useState } from 'react';
import { useNotifications } from '@/api/Notification/getNotifications';
import { readNotifications } from '@/api/Notification/readNotifications';
import { deleteNotifications } from '@/api/Notification/deleteNotifications';
import { useAuth } from '@/lib/auth';
import { Button } from '@/components/Elements';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '@/components/Layout';
import { Pagination } from '@/components/pagination/Pagination';
import { usePagination } from '@/hooks/usePagination';

function NotificationsData() {
  const { user } = useAuth();
  const { page, changePage } = usePagination();
  const { data, refetch } = useNotifications({ id: user?.id, page: page });
  const navigate = useNavigate();

  // State to manage selected notifications
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const toggleNotificationSelection = (notificationId) => {
    if (selectedNotifications.includes(notificationId)) {
      setSelectedNotifications(selectedNotifications.filter((id) => id !== notificationId));
    } else {
      setSelectedNotifications([...selectedNotifications, notificationId]);
    }
  };
  const allNotificationIds = data?.data?.map((notification) => notification._id) || [];

  const handleReadSelected = async () => {
    let payload = {
      ids: selectedNotifications,
    };
    await readNotifications(payload);
    setSelectedNotifications([]);
    refetch();
  };

  const handleSelectAll = async () => {
    if (selectedNotifications.length === allNotificationIds.length) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(allNotificationIds);
    }
  };

  const handleDeleteSelected = async () => {
    let payload = {
      ids: selectedNotifications,
    };
    await deleteNotifications(payload);
    setSelectedNotifications([]);
    refetch();
  };

  useEffect(() => {
    refetch();
  }, [page])

  return (
    <ContentLayout title="">
      <div className="my-4">
        <div className="main-content bg-white rounded">
          <div className="notification-drop">
            <div className="dropbottom">
              <div className="menu">
                <div className="header d-flex align-items-center justify-content-between border-b p-4">
                  <h4 className="pt-2 f-20 bold">Notifications</h4>
                  <div className="flex pt-2 space-x-4 notification-buttons">
                    <Button
                      onClick={handleReadSelected}
                      disabled={selectedNotifications.length === 0}
                    >
                      Mark as Read
                    </Button>
                    <Button
                      onClick={handleDeleteSelected}
                      disabled={selectedNotifications.length === 0}
                    >
                      Delete
                    </Button>
                    <Button onClick={handleSelectAll}>
                      {selectedNotifications.length === allNotificationIds.length
                        ? 'Unselect'
                        : 'Select all'}
                    </Button>
                  </div>
                </div>
                {data?.data?.length === 0 ? (
                  <li className="nonotifications">No new notifications</li>
                ) : (
                  <ul className="notify-list p-4">
                    {data?.data?.map((notification) => (
                      <li
                        key={notification._id}
                        onClick={async () => {
                          if (notification.type === 'user_created') {
                            await readNotifications({ ids: [notification?._id] });
                            refetch();
                            navigate(`/user/view/${notification?.data?.user?._id}`);
                          }
                          if (notification.type === 'chaperone_created') {
                            await readNotifications({ ids: [notification?._id] });
                            refetch();
                            navigate(`/chaperone/view/${notification?.data?.user?._id}`);
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                        className={`notification-item ${notification.read ? '' : 'unread'}`}
                      >
                        <div className="note-list d-flex justify-content-between mb-2">
                          {notification.read ? (
                            <span
                              className="d-flex f-13 pt-2 align-items-center notification-content"
                              style={{ marginLeft: '-10px' }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedNotifications.includes(notification._id)}
                                onChange={() => toggleNotificationSelection(notification._id)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ width: '20px', height: '20px' }} // Increase the size of the checkbox
                              />
                              <span style={{ paddingLeft: '10px' }}>{notification.content}</span>
                            </span>
                          ) : (
                            <span
                              className="d-flex f-13 pt-2 align-items-center notification-content"
                              style={{ marginLeft: '-10px' }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedNotifications.includes(notification._id)}
                                onChange={() => toggleNotificationSelection(notification._id)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ width: '20px', height: '20px' }} // Increase the size of the checkbox
                              />
                              <span style={{ color: 'red', marginRight: '5px' }}>•</span>
                              <span className="notify-cnt">
                                <strong> {notification.content}</strong>
                              </span>
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Pagination total={data?.totalCount} totalPages={data?.totalPages} page={data?.page} changePage={changePage} />
      </div>
    </ContentLayout>
  );
}

export default NotificationsData;
