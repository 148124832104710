import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Service } from '@/types';

const queryName = 'services';
export const deleteService = ({ id }: { id: string }) => {
  return axios.delete(`/admin/service/delete/${id}`);
};

type UseDeleteServiceOptions = {
  config?: MutationConfig<typeof deleteService>;
};
export const useDeleteService = ({ config }: UseDeleteServiceOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deleteService) => {
      await queryClient.cancelQueries(queryName);

      const previousService = queryClient.getQueryData<Service[]>(queryName);
      const itemToBeRemoved = document.querySelector(
        `.common-table-body tr[data-table-id='${deleteService.id}']`
      );
      const removeFromFrontend = () => {
        queryClient.setQueryData(
          queryName,
          previousService?.filter((service) => service._id !== deleteService.id)
        );
      };
      if (itemToBeRemoved) {
        itemToBeRemoved.classList.remove('animate__fadeInUp');
        itemToBeRemoved.classList.add('animate__fadeOutLeft');
        setTimeout(() => {
          removeFromFrontend();
        }, 300);
      } else {
        removeFromFrontend();
      }

      return { previousService };
    },
    onError: (_, __, context: any) => {
      if (context?.previousService) {
        queryClient.setQueryData(queryName, context.previousService);
      }
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(queryName);
      }, 200);
      addNotification({
        type: 'success',
        title: 'Service Deleted',
      });
    },
    ...config,
    mutationFn: deleteService,
  });
};
