import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { FaqData } from '@/features/owner/routes/AddFaq';
import { AxiosResponse } from 'axios';


export const getFaq = (page?: number): Promise<AxiosResponse<FaqData>> => {
  return axios.get(`/admin/faq-listing?page=${page ?? 1}`);
};


type QueryFnType = typeof getFaq;

type UseFaqData = {
  config?: QueryConfig<QueryFnType>;
  page?: number;
};

export const useFaqData = ({ config, page }: UseFaqData = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['faqlist'],
    queryFn: () => getFaq(page),
  });
};
