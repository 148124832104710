import { axiosMain } from '@/lib/axios';
import { Terms } from '@/types';

export type AddOnDTO = {
  data: {
    data: any;
    text: string;
    title: string;
  };
};
export const addUpdateTerms = ({ data }: AddOnDTO): Promise<Terms> => {
  return axiosMain.post(`/admin/terms`, data);
};


