import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { upperFirst } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { activeInactive } from '@/api/service/service';
import { useDeleteService } from '@/api/service/deleteService';
import { useAllService } from '@/api/service/getServices';
import { Table, Button, ConfirmationDialog, Drawer } from '@/components/Elements';
import { SelectField, Form } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
// import categorytype from '@/constants/categorytype';
import { usePagination } from '@/hooks/usePagination';
import { Appointment, Service } from '@/types';
import placeHolderProfile from '@/assets/placeholder.jpg';
import { useAllAppointments } from '@/api/appointments/getAppointments';
import { useDisclosure } from '@/hooks/useDisclosure';
import LoaderForAll from '@/components/Loader/LoaderForAll';
import eye from '@/assets/eye.png'
import moment from 'moment';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const schema = z.object({
  serviceType: z.string().min(1, 'Service type is required'),
});

type LoginValues = {
  serviceType: any;
};

export const AppointmentList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('');
  const [bookingId, setBookingId] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [applyLoad, setApplyLoad] = useState<boolean>(false);
  const [clearLoad, setClearLoad] = useState<boolean>(false);


  const { page, changePage } = usePagination();
  const { open, close, isOpen } = useDisclosure();
  const { data: services, isLoading: loading } = useAllService();


  const { data, refetch, isLoading } = useAllAppointments({
    config: {
      staleTime: 60000,
    },
    page: page,
    appointmentId: bookingId,
    service: service,
    date: date,
    status: status
  });

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const [isDeleting, setIsdeleting] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleClickOpen = async (id: any, activeStatus: string) => {
    await activeInactive(id, activeStatus);
    setSearchTerm('');
    refetch();
  };

  const [innerTab, setInnerTab] = useState(0);

  useEffect(() => {
    setInnerTab(innerTab);
  }, [innerTab]);

  const deleteServiceMutation = useDeleteService();

  const handleDelete = async (id: string) => {
    setIsdeleting(true);

    try {
      await deleteServiceMutation.mutateAsync({ id: id });
      setIsdeleting(false);
    } catch (error) {
      setIsdeleting(false);
    }
  };

  const handleSearch = async (search: any) => {
    if (search.length > 0) {
      setSearchTerm(search);
      setImmediate(() => refetch());
    } else {
      setSearchTerm('');
      setImmediate(() => refetch());
    }
  };

  const handleSearchType = async (search: any) => {
    if (search.length > 0) {
      setSearchType(search);
      setImmediate(() => refetch());
    } else {
      setSearchType('');
      setImmediate(() => refetch());
    }
  };

  const handleApplyFiter = () => {
    setApplyLoad(true);
    refetch();
    setApplyLoad(false);
    setClearFilter(false);
    close();

  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setBookingId('');
    setService('');
    setDate('');
    setStatus('');
    setClearLoad(false);
    console.log("clickeddddd")


  }

  useEffect(() => {
    refetch();
    close();
  }, [clearFilter])


  const options = [
    {
      label: 'Status',
      value: ''
    },
    {
      label: 'Approved',
      value: 'approved'
    },
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Cancelled',
      value: 'cancelled'
    },
    {
      label: 'Rejected',
      value: 'rejected'
    }
  ];

  const navs: {
    [key: string]: string
  } = {
    "accepted": "#1BC167",
    "rejected": "#CF5C5C",
    "cancelled": "#E2DB34",
    "pending": "#EE994A",
    "approved": "#013220"
  }



  return (
    <>
      {loading || isLoading ? <LoaderForAll /> :
        <ContentLayout title="">
          <div className="my-4">
            <div className="main-content bg-white rounded">
              <div className="row p-4">
                <div className="col-md-6">
                  <h4 className="f-20 bold">Appointments </h4>
                  <p className="f-14">You can view all appointments here</p>
                </div>
                <div className="col-6 md-6 d-flex justify-content-end align-items-center">
                  <Button
                    variant='primary'
                    className='px-4'
                    onClick={open}
                  >
                    Filters
                  </Button>
                </div>
                {/* <div className="col-md-6">
              <div className="flex justify-end items-center gap-3">
                <div className="">
                  <TextField
                    id="search"
                    type="search"
                    // label="Search"
                    placeholder="Search"
                    className="search"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    // sx={{ width: 600 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div> */}
              </div>

              <div className="user-table mt-4">
                <Table<Appointment>
                  page={page}
                  changePage={changePage}
                  totalPages={data?.totalPages}
                  total={data?.count ?? 0}
                  data={data?.data ?? []}
                  columns={[
                    {
                      title: 'Appointment ID',
                      field: 'appointmentUId'

                    },
                    {
                      title: 'User Name',
                      field: 'userId',
                      Cell({ entry: { userId } }) {
                        return (
                          <>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={userId?.image ? userId?.image : placeHolderProfile}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  position: "relative",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                                alt=""
                              />
                              <span>{upperFirst(userId?.name)}</span>
                            </div>
                          </>
                        )
                      },
                    },
                    {
                      title: 'Chaperone Name',
                      field: 'chaperone',
                      Cell({ entry: { chaperone } }) {
                        return (
                          <>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={chaperone?.image ? chaperone?.image : placeHolderProfile}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  position: "relative",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                                alt=""
                              />
                              <span>{upperFirst(chaperone?.name)}</span>
                            </div>
                          </>
                        )
                      },
                    },
                    {
                      title: 'Service',
                      field: 'service',
                      Cell({ entry: { service } }) {
                        return <span>{upperFirst(service?.serviceName)}</span>;
                      },
                    },
                    {
                      title: 'Date',
                      field: 'date',
                      Cell({ entry: { date } }) {
                        let dateParts = date.split('-');
                        let newDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
                        let formatedDate = moment(newDate).format('DD MMM YYYY')

                        return (
                          <span>{formatedDate}</span>
                        )
                      }
                    },
                    {
                      title: 'Status',
                      field: 'bookingId',
                      Cell({ entry: { bookingId } }) {
                        return <span style={{ color: navs[bookingId?.status] }}>{upperFirst(bookingId?.status)}</span>
                      }
                    },
                    {
                      title: 'Action',
                      field: '_id',
                      Cell({ entry: { _id } }) {
                        return (
                          <>
                            <Button
                              tabIndex={-1}
                              onClick={() => navigate(`/appointment/view/${_id}`)}
                              className="icon me-2"
                              variant="outline"
                              title="View"
                            >
                              <img src={eye} className='h-4' alt="" />
                            </Button>
                          </>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <Drawer
            title='Filters'
            isOpen={isOpen}
            onClose={close}
            size='sm'
            renderFooter={() => (
              <>
                <div className="row">
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={applyLoad}
                      onClick={handleApplyFiter}
                    >
                      Apply
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={clearLoad}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </>
            )}
          >
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className='form-control'
                  style={{ paddingRight: '0.75rem' }}
                  placeholder='Appointment ID'
                  value={bookingId}
                  onChange={(e) => setBookingId(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <select
                  name="service"
                  className='form-control mt-4'
                  style={{ paddingRight: '0.75rem' }}
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                >
                  {services?.allServices.map((val, index) => {
                    return (
                      <option key={index} value={val._id}>
                        {val.serviceName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <input
                  type="date"
                  className='form-control'
                  style={{ paddingRight: '0.75rem' }}
                  placeholder='date'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <select
                  name="service"
                  className='form-control mt-4'
                  value={status}
                  style={{ paddingRight: '0.75rem' }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {options.map(({ label, value }) => (
                    <option value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Drawer>
        </ContentLayout>
      }
    </>
  );
};
