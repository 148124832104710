import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getNotifications = (id: string, page: number): Promise<any> => {
  return axios.get(`/admin/notifications/${id}?page=${page ?? 1}`);
};

type QueryFnType = typeof getNotifications;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
  page: number
};

export const useNotifications = ({ id, config, page }: UseOptions = { id: '1', page: 1 }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-notifications'],
    queryFn: () => getNotifications(id, page),
  });
};
