import { useEffect, useState } from 'react';
import 'rsuite/dist/rsuite.min.css';
import Nav from 'rsuite/Nav';
import Sidenav from 'rsuite/Sidenav';
import Toggle from 'rsuite/Toggle';
import { Code } from '@rsuite/icons';
import dash from '@/assets/dashboard.png';
import faq from '@/assets/faq.png';
import privacy from '@/assets/file.png';
import password from '@/assets/password.png';
import user from '@/assets/user.png';
import pay from '@/assets/wallet.png';
import service from '@/assets/service.png';
import terms from '@/assets/terms.png';
import appoint from '@/assets/appoint.png';
import help from '@/assets/help_icon.png'
import { image } from 'faker/locale/az';
import { useLocation, useNavigate } from 'react-router-dom';

const Navs: {
  [key: string]: string
} = {
  '': "1",
  "user": "2-1",
  "chaperone": "2-2",
  "service": "4",
  "password": "3-2",
  "appointment": "5",
  "payment": "9",
  "payouts":"11",
  "terms": "6",
  "add-privacy-policy": "7",
  "faq": "8",
  "help-support": "10",
  "general-settings": "3-1"
}

export const MenuNav = ({ activeKey, setActiveKey }: { activeKey: string, setActiveKey: React.Dispatch<React.SetStateAction<string>> }) => {
  // const [activeKey, setActiveKey] = useState('1');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const pathname = location.pathname.split("/");
      if (pathname.length > 1) {
        const nowPath = pathname[1];
        setActiveKey(Navs[nowPath]);
      }
    }
  }, [location]);

  return (
    <div>
      <div className="sidebare">
        {/* <Toggle
          onChange={setExpand}
          checked={expand}
          checkedChildren="Open"
          unCheckedChildren="Collapse"
        />
        <hr /> */}
        <Sidenav className="sider tras">
          <Sidenav.Body>
            <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item eventKey="1" onClick={() => navigate('.')}>
                <img src={dash} alt="" /> <span>Dashboard</span>
              </Nav.Item>
              <Nav.Menu
                placement="rightStart"
                eventKey="2"
                title="User Management"
                className="user-icon"
              >
                <Nav.Item eventKey="2-1" onClick={() => navigate('/user')}>
                  Users
                </Nav.Item>
                <Nav.Item eventKey="2-2" onClick={() => navigate('/chaperone')}>
                  Chaperone
                </Nav.Item>
              </Nav.Menu>
              <Nav.Item eventKey="4" onClick={() => navigate('/service')}>
                <img src={service} alt="" /> <span>Services</span>
              </Nav.Item>
              <Nav.Item eventKey="5" onClick={() => navigate('/appointment')}>
                <img src={appoint} alt="" /> <span>Appointments</span>
              </Nav.Item>
              <Nav.Item eventKey="9" onClick={() => navigate('/payment')}>
                <img src={pay} alt="" /> <span>Payments</span>
              </Nav.Item>
              <Nav.Item eventKey="11" onClick={() => navigate('/payouts')}>
                <img src={service} alt="" /> <span>Payouts</span>
              </Nav.Item>
              <Nav.Item eventKey="6" onClick={() => navigate('/terms')}>
                <img src={terms} alt="" /> <span>Terms & Conditions</span>
              </Nav.Item>
              <Nav.Item eventKey="7" onClick={() => navigate('/add-privacy-policy')}>
                <img src={privacy} alt="" /> <span>Privacy Policy</span>
              </Nav.Item>
              <Nav.Item eventKey="8" onClick={() => navigate('/faq')}>
                <img src={faq} alt="" /> <span>FAQ</span>
              </Nav.Item>
              <Nav.Menu
                placement="rightStart"
                eventKey="3"
                title="Settings"
                className="setting-icon"
              >
                <Nav.Item eventKey="3-1" onClick={() => navigate('/general-settings')}>
                  General Settings
                </Nav.Item>
                <Nav.Item eventKey="3-2" onClick={() => navigate('/password')}>
                  Change Password
                </Nav.Item>
              </Nav.Menu>
              <Nav.Item eventKey="10" onClick={() => navigate('/help-support')}>
                <img src={help} alt="" /> <span>Help & Support</span>
              </Nav.Item>

            </Nav>
          </Sidenav.Body>
          <Sidenav />
        </Sidenav>
      </div>
    </div>
  );
};
