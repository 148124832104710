import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { User } from '@/types';

export type AddUserDTO = {
  data: {
    image: string;
    name: string;
    phoneNumber: string;
    email: string;
    // address: string;
    // other_address: string;
  };
};

export const addChaperone = ({ data }: AddUserDTO): Promise<User> => {
  return axios.post(`/admin/chaperone/add`, data);
};

type UseAddUserOptions = {
  config?: MutationConfig<typeof addChaperone>;
};

export const useCreateChaperone = ({ config }: UseAddUserOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newUser) => {
      await queryClient.cancelQueries('users');

      const previousUser = queryClient.getQueryData<User[]>('user');
      const newData = [...(previousUser || []), newUser.data];
      queryClient.setQueryData('users', newData);

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData('users', context.previousUser);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Chaperone Created',
      });
    },
    ...config,
    mutationFn: addChaperone,
  });
};
