import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  UserIcon,
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  ClockIcon,
  PhoneIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useNotifications } from '@/api/Notification/getNotifications';
import dash from '@/assets/dashboard.png';
import user from '@/assets/user.png';
import service from '@/assets/service.png';
import appoint from '@/assets/appoint.png';
import privacy from '@/assets/insurance.png';
import terms from '@/assets/contract.png';
import { useAuth } from '@/lib/auth';
import notify from '@/assets/download.png';
import { MenuNav } from './MenuNav';
import './layout.css';
import { upperFirst } from 'lodash';
import bell from '@/assets/Bell_icon.png';
import { Button } from '../Elements/Button';
import { Spinner } from '../Elements';
import { timeFromNow } from '@/utils/format';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const SideNavigation = () => {
  const navigation = [
    { name: 'Dashboard', to: '.', icon: dash },
    { name: 'User', to: './user', icon: user },
    { name: 'Chaperone', to: './chaperone', icon: UserIcon },
    { name: 'Service', to: './service', icon: service },
    { name: 'Appointment', to: './appointment', icon: appoint },
    { name: 'Payouts', to: './payouts', icon: service },
    { name: 'Terms & Conditions', to: './terms', icon: terms },
    { name: 'FAQ', to: './faq', icon: service },
    { name: 'Privacy Policy', to: './add-privacy-policy', icon: privacy },
  ].filter(Boolean) as SideNavigationItem[];

  // activeClassName="bg-gray-900 text-white"

  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className={clsx(
            'light-menu',
            'group flex items-center px-3 text-base font-medium rounded-md text-decoration-none'
          )}
        >
          {/* <item.icon
            className={clsx(
              'text-gray-400 group-hover:text-gray-300',
              'mr-4 flex-shrink-0 h-6 w-6'
            )}
            aria-hidden="true"
          /> */}
          <img src={item.icon} className={clsx('menu-icon', 'mr-4 flex-shrink-0')} alt="menu" />
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { user, logout, refetchUser } = useAuth();
  const { data, isLoading } = useNotifications({ id: user?.id });

  const navigate = useNavigate();
  React.useEffect(() => {
    refetchUser();
  }, [user]);

  const userNavigation = [
    { name: 'Your Profile', to: './profile' },
    {
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  const unreadNotifications = data?.data?.filter((item => !item.read));

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div className="d-flex flex-row gap-1 align-items-center">
            <div className="notification-box relative me-4">

              <div className="dropdown">
                <Link
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  to="/notifications"
                  id="dropdownMenuLink"
                  className="dark-bg p-2 flex items-center text-sm rounded-full focus:outline-none  dropdown-toggle"
                >
                  <img src={notify} alt="notification-icon" />
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <div className="notify-main-title px-4 pt-3 mb-0 f-22 bold">Notifications</div>
                  </li>
                  {unreadNotifications?.length === 0 &&
                    <li className='p-4'>
                      <p>No new notifications</p>
                    </li>
                  }
                  {isLoading ? <Spinner /> : unreadNotifications.slice(0, 3).map((notification) => {
                    return (
                      <>
                        <li
                          key={notification?._id}
                          onClick={async () => {
                            if (notification.type === 'user_created') {
                              navigate(`/user/view/${notification?.data?.user?._id}`);
                            }
                            if (notification.type === 'chaperone_created') {
                              navigate(`/chaperone/view/${notification?.data?.user?._id}`);
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                          className={`notification-item ${notification.read ? '' : 'unread'}`}
                        >
                          <div className="notification-wrap">
                            <div className="row px-4">
                              <div className="col-md-9 desc flex-column align-items-left justify-content-start">
                                {/* <h6 className="f-16 bold mb-1 d-block w-100 text-left">
                                  Notification Title
                                </h6> */}
                                <p className="f-13 mb-0 w-100 text-left">
                                  {notification?.content}
                                </p>
                              </div>
                              <div
                                className="col-md-3 date justify-content-end"
                                style={{ fontWeight: '400' }}
                              >
                                <span className="f-12">{timeFromNow(notification?.createdAt)}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <div className="notification-wrap">
                            <div className="row px-4">
                              <div className="col-md-9 desc flex-column align-items-left justify-content-start">
                                <h6 className="f-16 bold mb-1 d-block w-100 text-left">
                                  Notification Title
                                </h6>
                                <p className="f-13 mb-0 w-100 text-left">
                                  Lorem ipsum dolor ratione explicabo in magni dolor recusandae quisquam
                                  aliquam.
                                </p>
                              </div>
                              <div
                                className="col-md-3 date justify-content-end"
                                style={{ fontWeight: '400' }}
                              >
                                <span className="f-12">5 mins ago</span>
                              </div>
                            </div>
                          </div>
                        </li> */}

                      </>
                    )
                  })}
                  <li>
                    <div className="button-view text-center">
                      <Button size="sm" onClick={() => navigate('/notifications')}>
                        View All Notifications
                      </Button>
                    </div>
                  </li>
                </ul >
              </div>
              {/* <Link
                to="/notifications"
                className="bg-gray-200 p-2 flex items-center text-sm rounded-full focus:outline-none"
              >
                <img src={notify} alt="notification-icon" />
              </Link> */}
              <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                {data?.unread || 0}
              </div>
            </div>

            <div>
              {!user?.image ? (
                <Menu.Button className="max-w-xs  bg-gray-200 p-2 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open user menu</span>
                  <UserIcon className="h-8 w-8 rounded-full" />
                </Menu.Button>
              ) : (
                <Menu.Button>
                  <img
                    src={user?.image}
                    className="rounded-full"
                    alt=""
                    style={{ height: '3.2rem', width: '3.2rem' }}
                  />
                </Menu.Button>
              )}
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className="font-semibold" style={{ color: '#150035', fontSize: '14px' }}>
                  {upperFirst(user?.name)}
                </span>
              </div>
              <div>
                <span className="font-medium" style={{ color: '#757575', fontSize: '14px' }}>
                  {upperFirst(user?.role)}
                </span>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )
      }
    </Menu >
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 sidebar pb-4 grey-dark">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = ({
  activeKey,
  setActiveKey,
}: {
  activeKey: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4 grey-dark">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 ps-3 py-4 grey-dark sider space-y-1">
              {/* <SideNavigation /> */}
              <MenuNav activeKey={activeKey} setActiveKey={setActiveKey} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
const Logo = () => {
  return (
    <Link className="flex items-center text-center text-white text-decoration-none" to=".">
      {/* <img
        className="h-8 w-auto"
        src={logo ? logo : 'https://uilogos.co/img/logotype/circle.png'}
        alt="Workflow"
      /> */}
      <span className="text-xl dark font-semibold text-decoration-none">Taylor</span>
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState('1');

  const Navs: {
    [key: string]: string
  } = {
    Dashboard: '1',
    'User Management': '2-1',
    chaperone: '2-2',
    services: '4',
    appointments: '5',
    payments: '9',
    payouts: '11',
    'terms & conditiions': '6',
    'Privacy Policy': '7',
    'Help & support': '10',
    faq: '8',
  };

  let heading = '';
  for (let key in Navs) {
    if (activeKey === Navs[key]) {
      heading = key;
    }
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar activeKey={activeKey} setActiveKey={setActiveKey} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div
          className="relative z-10 flex-shrink-0 flex bg-white header-top align-items-center"
          style={{ height: '4.5rem' }}
        >
          <h5 className="f-20 mb-0">{heading}</h5>
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="right-header">
                {/* <div className="notify-drop">
                  <img src={notify} className="notify-icn" alt="" />
                </div> */}
                <UserNavigation />
              </div>
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};
