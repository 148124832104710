import { useFaqData } from '@/api/getFaq'
import { Button, ConfirmationDialog, Spinner, Table } from '@/components/Elements'
import { ContentLayout } from '@/components/Layout'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaqData } from './AddFaq';
import { usePagination } from '@/hooks/usePagination'
import { deleteFaq } from '@/api/deleteFaq'
import { useNotificationStore } from '@/stores/notifications';
import eye from '@/assets/eye.png';


type GetFaq = {
  _id: string;
  question: string;
  answer: string;
}

const Faq = () => {
  const navigate = useNavigate();
  const { page, changePage } = usePagination();
  const { data, isLoading, isFetching, refetch } = useFaqData({ page: page });
  const { addNotification } = useNotificationStore();
  const [reload, setReload] = useState<boolean>(false);

  const handleDelete = async (id: string) => {
    await deleteFaq(id);
    addNotification({
      type: 'success',
      title: 'FAQ deleted successfuly'
    });
    setReload(true);
  }

  useEffect(() => {
    refetch();
  }, [reload])

  useEffect(() => {
    refetch();
  }, [page])

  return (
    <>
      {isLoading || isFetching ? <Spinner /> :
        <ContentLayout title=''>
          <div className="my-4">
            <div className="row">
              <div className="col-md-6">
                <h4 className="bold">FAQ Listing</h4>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <Button
                  onClick={() => navigate('/add-faq')}>
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Table<GetFaq>
              page={page}
              changePage={changePage}
              totalPages={data?.totalPages}
              total={data?.totalCount}
              data={data?.data ?? []}
              columns={[
                { title: "Question", field: "question" },
                {
                  title: 'Answer',
                  field: 'answer',
                  Cell({ entry: { answer } }) {
                    return <span>{`${answer.slice(0, 50)}${answer.length > 50 ? '..............' : ''}`}</span>
                  }
                },
                {
                  title: 'Action',
                  field: 'action',
                  Cell({ entry: { _id } }) {
                    return (
                      <>
                        <Button
                          tabIndex={-1}
                          onClick={() => navigate(`/view-faq/${_id}`)}
                          className="icon me-2"
                          variant="outline"
                          title="View"
                        >
                          <img src={eye} className='h-4' alt="" />
                        </Button>

                        <Button variant='outline' className='icon me-2' onClick={() => navigate(`/edit-faq/${_id}`)}>
                          <i class="fas fa-edit"></i>
                        </Button>

                        <ConfirmationDialog
                          triggerButton={
                            <Button
                              className="icon me-2"
                              variant="outline"
                              title="Delete"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          }
                          confirmButton={
                            <Button
                              variant='primary'
                              className='expanded-btn'
                              onClick={() => handleDelete(_id)}
                            >
                              Delete
                            </Button>
                          }
                          title='Delete FAQ'
                          icon='danger'
                          body="Are you sure you want to delete this FAQ"
                        />
                      </>
                    )

                  }
                }
              ]}
            />
          </div>
        </ContentLayout>
      }
    </>
  )
}

export default Faq