import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import CloseIcon from '@mui/icons-material/Close';

import { uploadImage } from '@/api/uploadImage';
import { useCreateUser } from '@/api/user/addUser';
import { useUpdateUser } from '@/api/user/updateUser';
import { Button } from '@/components/Elements';
import { Form, InputField, InputPhone } from '@/components/Form';
import plus from '@/assets/plus.png';

import { ContentLayout } from '@/components/Layout';
import { useSelectFile } from '@/hooks/useSelectFile';
import { User } from '@/types';
import { Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';
import placeHolderProfile from '@/assets/placeholder.jpg';

// ============ POPUP ====================
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

type CreateUser = {
  image: string;
  name: string;
  phoneNumber: string;
  email: string;
  // address: string;
  // other_address: string;
};

const schema = z.object({
  name: z
    .string()
    .min(1, 'Please enter customer name')
    .max(30, 'Please enter maximum 30 letters')
    .regex(/^[^\d]+$/, 'User name cannot contain numbers'),
  phoneNumber: z.custom(isValidMobile, isValidMobileMsg),
  email: z.string().min(1, 'Please enter email address'),

  // other_address: z.string().min(1, 'Please enter other address'),
});

export const CreateUser = ({ user }: { user?: User }) => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { file: userFile, preview: filePreview, handleChange: handleFileChange } = useSelectFile();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [user]);
  useEffect(() => {
    if (userFile) {
      setError(false);
    }
  }, [userFile]);

  const updateUserMutation = useUpdateUser();
  const addUserMutation = useCreateUser();

  const handleSubmit = async (values: CreateUser) => {
    try {
      setLoading(true);

      let image = user?.image ?? '';

      if (userFile) {
        const response = await uploadImage({ file: userFile });
        image = response.url;
      } else {
        image = user?.image ?? '';
      }
      if (image == '') {
        setError(true);
        return;
      }
      const payload: any = {
        image: image,
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        // address: values.address,
        // other_address: values.other_address,
      };

      if (user) {
        await updateUserMutation.mutateAsync({ data: payload, id: user?.id });
      } else {
        const d = await addUserMutation.mutateAsync({ data: payload });
      }

      navigate('/user');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (user?.address) {
  //     setReload(true);
  //     setTimeout(() => setReload(false), 0);
  //   }
  // }, [user]);

  return (
    <ContentLayout title={!user ? 'Add User' : 'Edit User'}>
      <div className="row">
        <div className="col-12 col-md-10">
          <div className="card shadow border-0 rounded add-user-form mt-4 space-card">
            <p className="f-20 bold mb-1">Please enter the following details</p>
            <div className="my-4">
              {!reload && (
                <Form<CreateUser, typeof schema>
                  onSubmit={handleSubmit}
                  schema={schema}
                  options={{
                    defaultValues: {
                      name: user?.name,
                      phoneNumber: user?.phoneNumber ? user?.phoneNumber.toString() : '',
                      email: user?.email,
                      // other_address: user?.other_address,
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <label>Image</label>
                      <div>
                        <label htmlFor="userInput">
                          <div className="foto">
                            {filePreview ? (
                              <img src={filePreview} alt="" className="previewImage setImage" />
                            ) : user?.image ? (
                              <img
                                src={user?.image}
                                alt=""
                                className="previewImage setImage"
                                width="60"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                              />
                            ) : (
                              <img src={plus} alt="" width="60" />
                            )}
                          </div>
                        </label>
                        <input
                          accept="image/*"
                          onChange={handleFileChange}
                          type="file"
                          id="userInput"
                          className="d-none"
                        />
                        {error && <p className='text-red-500'>Image is Required/* </p>}
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['name']}
                            registration={register('name', {
                              // value: user?.user,
                            })}
                            type="text"
                            label="Customer Name"
                            placeholder="Customer Name"
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <div className="prepend-icon">
                            <InputPhone
                              label="Phone Number"
                              error={formState.errors['phoneNumber']}
                              registration={register('phoneNumber')}
                              value={user?.phoneNumber ? user?.phoneNumber.toString() : ''}
                            />
                          </div>

                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['email']}
                            registration={register('email', {})}
                            type="text"
                            label="Email"
                            placeholder="Email"
                          />
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <Button isLoading={loading} type="submit" className="expanded-btn">
                            {user ? 'Update' : 'Add User'}
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
