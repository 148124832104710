import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from '@/api/user/getUser';
import { ContentLayout } from '@/components/Layout';

import { CreateUser } from './AddUser';

export const EditUser = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useUser({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <ContentLayout title="">
        <div>
          <CreateUser user={data?.data} />
        </div>
      </ContentLayout>
    </div>
  );
};
