import { axios } from '@/lib/axios';
import { Service } from '@/types';

export type ServiceDTO = {
  data: any;
  service: string;
  description: string;
  serviceType: string;
};

export const searchService = async (value: string | null = null): Promise<Service[]> => {
  return axios.get(`/admin/service/search?name=${value}`);
};

export const searchServiceType = async (value: string | null = null): Promise<Service[]> => {
  return axios.get(`/admin/service/search?type=${value}`);
};

export const activeInactive = (id: string, status: string): Promise<Service> => {
  return axios.put(`/admin/service/${id}/active-inactive?status=${status}`);
};
