import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export const getUser = (id: string): Promise<User> => {
  return axios.get(`/admin/users/${id}`);
};

type QueryFnType = typeof getUser;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useUser = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-user'],
    queryFn: () => getUser(id),
  });
};
