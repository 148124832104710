import { SearchIcon } from '@heroicons/react/outline';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { upperFirst } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useDeleteUser } from '@/api/user/deleteUser';
import { useAllUser } from '@/api/user/getUsers';
import placeHolderProfile from '@/assets/placeholder.jpg';
import { Table, Card, Button, ConfirmationDialog } from '@/components/Elements';
import { SelectField, Form } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { User as IUser } from '@/types';
import { usePagination } from '@/hooks/usePagination';
import eye from '@/assets/eye.png';
import edit from '@/assets/edit.png';
import bucket from '@/assets/deleteIcon.png';
import Switch from '@mui/material/Switch';
import { useNotificationStore } from '@/stores/notifications';
import { changeUserStatus } from '@/api/changeUserStatus';
import LoaderForAll from '@/components/Loader/LoaderForAll';


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const schema = z.object({
  categoryType: z.string().min(1, 'Category type is required'),
});

type LoginValues = {
  categoryType: string;
};
// ------------------------------------------

export const User = () => {
  const { page, changePage } = usePagination();
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isLoading, isFetching, refetch } = useAllUser({
    searchKey: searchTerm,
    page: page,
  });

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const [ids, setids] = useState(0);

  const [isDeleting, setIsdeleting] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<any>();

  const handleClickOpen = async (id: any, activeInactive: string) => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/users/${id}/active-inactive?status=${activeInactive}`
    );
    refetch();
    setids(id);
    setOpen(true);
  };

  const [value, setValue] = React.useState(0);

  const [setTabNumberVal, setTabNumber] = useState(0);
  const [innerTab, setInnerTab] = useState(0);

  useEffect(() => {
    setInnerTab(innerTab);
  }, [innerTab]);

  useEffect(() => {
    if (setTabNumberVal !== 0) {
      setValue(setTabNumberVal);
    }
  }, [setTabNumber, setTabNumberVal, innerTab]);

  const { mutate: mutateDelete } = useDeleteUser();

  const handleDelete = async (id: string) => {
    mutateDelete({ id });
  };
  const handleSearch = async (search: any) => {
    if (search.length > 0) {
      setSearchTerm(search);
      setImmediate(() => refetch());
    } else {
      setSearchTerm('');
      setImmediate(() => refetch());
    }
  };

  const handleStatus = async (id: string) => {
    await changeUserStatus(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: "Status changed successfuly"
    });

  };
  useEffect(() => {
    setFilteredData(data?.data);
  }, [data?.data]);


  return (
    <>
      {isLoading ? <LoaderForAll /> :
        <ContentLayout title="">
          <div className="my-4">
            <div className='main-content bg-white rounded'>
              <div className="row p-4">
                <div className="col-md-6">
                  <h4 className="bold f-22">User Listing</h4>
                </div>
                <div className="col-md-6">
                  <div className="flex justify-end items-center gap-3">
                    <div className="">
                      <Button size="md" className="expanded-btn" onClick={() => navigate('/user/add')}>
                        Add Users
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6 mx-3">
                  <TextField
                    id="search"
                    type="search"
                    // label="Search"
                    placeholder="Search"
                    className="search top-search"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    // sx={{ width: 600 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="user-table mt-3">
                <Table<IUser>
                  page={page}
                  changePage={changePage}
                  totalPages={data?.totalPages}
                  total={data?.count ?? 0}
                  data={data?.data ?? []}
                  columns={[

                    {
                      title: 'User Name',
                      field: 'name',
                      Cell({ entry: { name, image } }) {
                        return (
                          <>
                            <div className='d-inline-block' style={{width: "max-content"}}>
                              <img
                                src={image ? image : placeHolderProfile}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  position: "relative",
                                  display: "inline-block"
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                                alt=""
                              />
                              <span style={{
                                position: 'relative',    display: "inline-block", marginLeft: "15px"

                              }}
                              >{upperFirst(name)}</span>
                            </div>
                          </>

                        );
                      },
                    },
                    {
                      title: 'Email',
                      field: 'email',
                      Cell({ entry: { email } }) {
                        return <span>{upperFirst(email)}</span>;
                      },
                    },
                    {
                      title: 'Phone Number',
                      field: 'phoneNumber',
                      Cell({ entry: { phoneNumber } }) {
                        return <span>{phoneNumber}</span>;
                      },
                    },
                    {
                      title: 'Since Joined',
                      field: 'createdAt',
                      Cell({ entry: { createdAt } }) {
                        return <span>{moment(createdAt).format('DD MMMM YYYY')}</span>;
                      },
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      Cell({ entry: { status } }) {
                        return <span>{upperFirst(status)}</span>
                      },
                    },
                    {
                      title: 'Action',
                      field: 'id',
                      Cell({ entry: { _id, id, status } }) {
                        return (
                          <>
                            <Button
                              tabIndex={-1}
                              onClick={() => navigate(`/user/view/${id}`)}
                              className="icon me-2"
                              variant="outline"
                              title="View"
                            >
                              <img src={eye} className='h-4' alt="" />
                            </Button>
                            <Button
                              tabIndex={-1}
                              onKeyDown={() => handleClickOpen(id, status)}
                              onClick={() => navigate(`/user/edit/${id}`)}
                              className="icon me-2"
                              variant="outline"
                              title="Edit"
                            >
                              <img src={edit} className='h-5' alt="" />
                            </Button>


                            <ConfirmationDialog
                              triggerButton={
                                <Button
                                  tabIndex={-1}
                                  className="icon me-2"
                                  variant="outline"
                                  title="Delete"
                                >
                                  <img src={bucket} className='h-5' alt="" />
                                </Button>
                              }
                              confirmButton={
                                <Button
                                  isLoading={isDeleting}
                                  onClick={() => handleDelete(id)}
                                  variant="primary"
                                  className="expanded-btn"
                                >
                                  Delete
                                </Button>
                              }
                              title="Delete User"
                              icon="danger"
                              body="Are you sure you want to delete this user ?"
                            />
                            {status === 'inactive' ? (
                              <ConfirmationDialog
                                triggerButton={
                                  <Button
                                    tabIndex={-1}
                                    // onKeyDown={() => handleClickOpen(_id)}
                                    onClick={() => handleClickOpen(id, status)}
                                    className="icon me-2"
                                    title="Active"
                                    variant="outline"
                                  >
                                    <i className="fa-solid fa-check"></i>{' '}
                                  </Button>
                                }
                                confirmButton={
                                  <Button
                                    isLoading={isDeleting}
                                    onClick={() => handleClickOpen(id, 'active')}
                                    variant="primary"
                                  >
                                    Active
                                  </Button>
                                }
                                title="Active User"
                                icon="danger"
                                body="Are you sure you want to active this user ?"
                              />
                            ) : (
                              <ConfirmationDialog
                                triggerButton={
                                  <Button
                                    tabIndex={-1}
                                    // onKeyDown={() => handleClickOpen(_id)}
                                    onClick={() => handleClickOpen(id, status)}
                                    className="icon me-2"
                                    title="Inactive"
                                    variant="outline"
                                  >
                                    <i className="fa-solid fa-ban"></i>{' '}
                                  </Button>
                                }
                                confirmButton={
                                  <Button
                                    isLoading={isDeleting}
                                    onClick={() =>
                                      handleClickOpen(id, status === 'inactive' ? 'active' : 'inactive')
                                    }
                                    variant="primary"
                                  >
                                    Inactive
                                  </Button>
                                }
                                title="Inactive User"
                                icon="danger"
                                body="Are you sure you want to Inactive this user ?"
                              />
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </ContentLayout>
      }
    </>
  );
};
