import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type HelpQuery = {
    id?: string;
    _id: string;
    name: string;
    email: string;
    phoneNumber: string;
    query: string;
    response?: string;
    status: string;
    createdAt: string;
}

export const getHelpQueries = (searchKey: string, statusKey: string, date: string, page?: number): Promise<AxiosResponse<HelpQuery>> => {
    if (searchKey || statusKey || date) {
        return axios.get(`/admin/get-help-queries?page=${page ?? 1}&searchKey=${searchKey}&statusKey=${statusKey}&date=${date}`);
    }
    return axios.get(`/admin/get-help-queries?page=${page ?? 1}`)
};

type QueryFnType = typeof getHelpQueries;

type useQueries = {
    config?: QueryConfig<QueryFnType>;
    searchKey: string;
    statusKey: string;
    date: string;
    page?:number;
}

export const useHelpQueries = ({ config,searchKey,statusKey,date,page }: useQueries = {page: 1, searchKey: '',statusKey: '', date: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['helplist'],
        queryFn: () => getHelpQueries(searchKey,statusKey,date,page)
    })
}