import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { Controller, useForm, UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import { Box } from '@mui/system';
import { Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Option = {
  label: React.ReactNode;
  value: string | number;
};

type InputFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  value?: string[];
  registration: Partial<UseFormRegisterReturn>;
};

export function MultiSelect(props: InputFieldProps) {
  const { registration, error, options, label, value } = props;
  const { control, setValue } = useForm();
  const { onChange: originalFormChange, name } = registration;

  React.useEffect(() => {
    if (value && name) {
      setValue(name, value);
    }
  }, [value]);

  const handleChange =
    (change: (event: SelectChangeEvent<string>) => void) => (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;
      const e = {
        target: {
          value,
          name,
        },
      };
      originalFormChange?.(e);
      change(event);
    };

  return (
    <FieldWrapper error={error}>
      <Controller
        name={name ?? ''}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
              error={!!error?.message}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={value ?? []}
              onChange={handleChange(onChange)}
              input={<OutlinedInput label={label} />}
              renderValue={(selected: any) => {
                const eventNames =
                  selected?.map((i: string) => {
                    const ev = options.find((j) => j.value === i);
                    return ev?.label;
                  }) ?? [];

                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {eventNames.map((value: any, index: any) => (
                      <Chip key={index} label={value} />
                    ))}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  <Checkbox checked={value?.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </FieldWrapper>
  );
}
