import { HelpQuery, useHelpQueries } from '@/api/getHelpQueries';
import { Button, Spinner, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePagination } from '@/hooks/usePagination';
import { upperFirst } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Form, TextAreaField } from '@/components/Form';
import * as z from 'zod';
import { getSingleQuery, useSingleQuery } from '@/api/getSingleQuery';
import { postQueryResponse } from '@/api/postQueryResponse';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
  response: z.string().min(1, 'Please enter your response'),
});

export type ResponseValues = {
  response: string;
};

const HelpSupport = () => {
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [singleQueryData, setSingleData] = useState<HelpQuery>();
  const [loading, setLoading] = useState<boolean>(false);

  const { page, changePage } = usePagination();
  const { open, isOpen, close } = useDisclosure();
  const { addNotification } = useNotificationStore();
  const { data, isLoading, isFetching, refetch } = useHelpQueries({
    page: page,
    searchKey: search,
    statusKey: status,
    date: date,
  });

  const options = [
    {
      label: 'Status',
      value: '',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Resolve',
      value: 'resolve',
    },
  ];

  const handleBtnClick = () => {
    setClearFilter(false);
    refetch();
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const handleAllQuery = () => {
    setSearch('');
    setStatus('');
    setDate('');
    setClearFilter(true);
  };

  useEffect(() => {
    refetch();
  }, [page, clearFilter]);

  const handleAction = async (id: string) => {
    if (id) {
      const singleData = await getSingleQuery(id);
      setSingleData(singleData?.data);
    }
    open();
  };

  const handleSubmit = async (values: ResponseValues) => {
    if (singleQueryData?._id) {
      try {
        setLoading(true);
        await postQueryResponse(singleQueryData?._id, values);
        addNotification({
          type: 'success',
          title: 'Response submitted successfuly',
        });
        setLoading(false);
        close();
        refetch();
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <ContentLayout title="">
        <div className="bg-white rounded my-4">
          <div className="row p-4">
            <div className="col-md-6 bold f-22">Help & Support</div>
          </div>
          <div className="row p-4">
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                style={{ paddingRight: '0.75rem' }}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <select
                name="location"
                className="form-select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {options.map(({ label, value }) => (
                  <option key={label?.toString()} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                placeholder="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="col-md-2" style={{ textAlign: "right" }}>
              <Button className="px-4" onClick={handleBtnClick}>
                Search
              </Button>
            </div>
            {/* <div className="col-md-2">
              <Button onClick={handleAllQuery}>All Queries</Button>
            </div> */}
          </div>
          <div className="p-4">
            <h5 className="font-bold">Queries</h5>
          </div>
          {isLoading || isFetching ? (
            <Spinner />
          ) : (
            <div className="table p-4">
              <Table<HelpQuery>
                page={page}
                total={data?.totalCount}
                totalPages={data?.totalPages}
                changePage={changePage}
                data={data?.data ?? []}
                columns={[
                  { title: 'User Name', field: 'name' },
                  { title: 'Email', field: 'email' },
                  {
                    title: 'Date',
                    field: 'createdAt',
                    Cell({ entry: { createdAt } }) {
                      const newDate = moment(createdAt).format('DD MMM YYYY');
                      return <span>{newDate}</span>;
                    },
                  },
                  { title: 'Queries', field: 'query' },
                  {
                    title: 'Action',
                    field: 'status',
                    Cell({ entry: { status, _id } }) {
                      return (
                        <button onClick={() => handleAction(_id)}>
                          <span
                            style={status === 'resolve' ? { color: 'green' } : { color: 'red' }}
                          >
                            {upperFirst(status)}
                          </span>
                        </button>
                      );
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </ContentLayout>

      <div className="helpModals">
        <Modal
          open={isOpen}
          onClose={() => close()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="query-modal-content" sx={style}>
            <Typography id="modal-modal-title" className="f-22" variant="h6" component="h2">
              Query
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="col-md-12 col-sm-12">
                <Form<ResponseValues, typeof schema>
                  onSubmit={handleSubmit}
                  schema={schema}
                  options={{
                    defaultValues: {
                      response: singleQueryData?.response ? singleQueryData?.response : '',
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <div className="row">
                        <div className="popupRes f-14">
                          <p className="row w-100 m-0 quryCont border-bottom">
                            <div className="col-md-3">
                              <span>Name : </span>
                            </div>
                            <div className="col-md-9">{singleQueryData?.name} </div>
                          </p>
                          <p className="row w-100 m-0 quryCont border-bottom">
                            <div className="col-md-3">
                              <span>Email : </span>
                            </div>
                            <div className="col-md-9"> {singleQueryData?.email} </div>
                          </p>
                          <p className="row w-100 m-0 quryCont border-bottom">
                            <div className="col-md-3">
                              {' '}
                              <span>Phone : </span>
                            </div>
                            <div className="col-md-9"> {singleQueryData?.phoneNumber} </div>
                          </p>
                          <p className="row w-100 m-0 quryCont border-bottom">
                            <div className="col-md-3">Query:</div>
                            <div className="col-md-9">{singleQueryData?.query}</div>
                          </p>
                          {singleQueryData?.status === 'resolve' && (
                            <p className="row  w-100 m-0 quryCont border-bottom">
                              <div className="col-md-3">Response:</div>
                              <div className="col-md-9">{singleQueryData?.response}</div>
                            </p>
                          )}
                        </div>
                        {singleQueryData?.status === 'pending' && (
                          <>
                            <div className="col-12 mt-3">
                              <TextAreaField
                                label="Resolve the Query"
                                error={formState.errors['response']}
                                registration={register('response')}
                              />
                            </div>

                            <div className="mt-3 d-flex justify-content-center align-items-center">
                              <Button type="submit" className="mt-2" isLoading={loading}>
                                Resolve
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default HelpSupport;
