import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';

import placeHolderProfile from '@/assets/placeholder.jpg';
import placeHolderNoPic from '@/assets/no_photo_placeholder.jpg';
import moment from 'moment';
import { concat, upperFirst } from 'lodash';
import LoaderForAll from '@/components/Loader/LoaderForAll';
import { useUser } from '@/api/user/getUser';
import { Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import { useAppointment } from '@/api/appointments/getAppointment';

export const ViewAppointments = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useAppointment({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderForAll />
      ) : (
        <ContentLayout title="View Appointment Details">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="card shadow border-0 rounded add-user-form mt-4">
                <div className="flex flex-col gap-2">
                  <div className="p-4 pb-1 detail-col">
                    <span className="d-block f-20 mb-4 details-title">
                      <b>User Details </b>
                    </span>
                    <div className="flex items-center gap-4 driver-flex">
                      <div className="driver-left">
                        <b>Name : </b>
                      </div>
                      <div className="driver-rghtt">{upperFirst(data?.data?.userId?.name)}</div>
                    </div>

                    <div className="flex items-center gap-4  driver-flex">
                      <div className="driver-left">
                        <b>Email : </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.userId?.email}</div>
                    </div>
                    <div className="flex items-center gap-4 driver-flex">
                      <div className="driver-left">
                        <b>Phone Number: </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.userId?.phoneNumber}</div>
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 pb-1 pt-0 detail-col">
                    <span className="d-block f-20 mb-4 details-title">
                      <b>Chaperone Details </b>
                    </span>
                    <div className="flex items-center gap-4 driver-flex">
                      <div className="driver-left">
                        <b>Name : </b>
                      </div>
                      <div className="driver-rghtt">{upperFirst(data?.data?.chaperone?.name)}</div>
                    </div>
                    <div className="flex items-center gap-4  driver-flex">
                      <div className="driver-left">
                        <b>Email : </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.chaperone?.email}</div>
                    </div>
                    <div className="flex items-center gap-4 driver-flex">
                      <div className="driver-left">
                        <b>Phone Number: </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.chaperone?.phoneNumber}</div>
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 pb-1 pt-0 detail-col">
                    <span className="d-block f-20 mb-4 details-title">
                      <b>Service Details </b>
                    </span>
                    <div className="flex items-center gap-4 driver-flex">
                      <div className="driver-left">
                        <b>Name : </b>
                      </div>
                      <div className="driver-rghtt">
                        {upperFirst(data?.data?.service?.serviceName)}
                      </div>
                    </div>
                    <div className="flex items-center gap-4  driver-flex">
                      <div className="driver-left">
                        <b>Price : </b>
                      </div>
                      <div className="driver-rghtt">$ {data?.data?.service?.price}</div>
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 pb-1 pt-0 detail-col">
                  <span className="d-block f-20 mb-4 details-title">
                    <b>Booking Details </b>
                  </span>
                  <div className="flex items-center gap-4 driver-flex">
                    <div className="driver-left">
                      <b>Appointment Type : </b>
                    </div>
                    <div className="driver-rghtt">
                      {upperFirst(data?.data?.bookingId?.appointmentType)}
                    </div>
                  </div>
                  <div className="flex items-center gap-4  driver-flex">
                    <div className="driver-left">
                      <b>Date : </b>
                    </div>
                    <div className="driver-rghtt">{data?.data?.bookingId?.date}</div>
                  </div>
                  <div className="flex items-center gap-4  driver-flex">
                    <div className="driver-left">
                      <b>Day : </b>
                    </div>
                    <div className="driver-rghtt">{data?.data?.day}</div>
                  </div>
                  <div className="flex items-center gap-4  driver-flex">
                    <div className="driver-left">
                      <b>Payment Id : </b>
                    </div>
                    <div className="driver-rghtt">{data?.data?.bookingId?.paymentId}</div>
                  </div>
                  <div className="flex items-center gap-4  driver-flex">
                    <div className="driver-left">
                      <b>Card Id : </b>
                    </div>
                    <div className="driver-rghtt">{data?.data?.bookingId?.cardId}</div>
                  </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </ContentLayout>
      )}
    </div>
  );
};
