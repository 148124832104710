import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Appointment, User } from '@/types';

export const getAppointment = (id: string): Promise<Appointment> => {
  return axios.get(`/admin/appointment/${id}`);
};

type QueryFnType = typeof getAppointment;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useAppointment = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-appointment'],
    queryFn: () => getAppointment(id),
  });
};
