import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Terms } from '@/types';
import { axios } from '@/lib/axios';

export const getTermsCondition = (): Promise<Terms> => {
  return axios.get('/users/get?type=terms');
};

type QueryFnType = typeof getTermsCondition;

type investorData = {
  config?: QueryConfig<QueryFnType>;
};

export const useTermsCondition = ({ config }: investorData = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['content'],
    queryFn: () => getTermsCondition(),
  });
};
