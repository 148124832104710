import { useAllService } from '@/api/service/getServices';
import { TransactionDetails, useTransactionList } from '@/api/transactions/getTransactionList'
import { Button, ConfirmationDialog, Drawer, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout'
import LoaderForAll from '@/components/Loader/LoaderForAll';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePagination } from '@/hooks/usePagination';
import placeHolderProfile from '@/assets/placeholder.jpg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import { payChaperone } from '@/api/payChaperone';
import { useNotificationStore } from '@/stores/notifications';

const Payment = () => {
  const [bookingId, setBookingId] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [applyLoad, setApplyLoad] = useState<boolean>(false);
  const [clearLoad, setClearLoad] = useState<boolean>(false);
  const [payLoader, setPayLoader] = useState<boolean>(false);

  const { page, changePage } = usePagination()
  const { data, isLoading, isFetching, refetch } = useTransactionList({
    page: page,
    bookingId: bookingId,
    service: service,
    date: date,
    status: status
  });
  const { data: services, isLoading: loading } = useAllService();
  const { open, close, isOpen } = useDisclosure();
  const { addNotification } = useNotificationStore();

  const options = [
    {
      label: 'Status',
      value: '',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
  ];

  const handleApplyFiter = () => {
    setApplyLoad(true);
    refetch();
    setApplyLoad(false);
    setClearFilter(false);
    close();
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setBookingId('');
    setService('');
    setDate('');
    setStatus('');
    setClearLoad(false);
  };

  const navs: {
    [key: string]: string
  } = {
    "accepted": "#1BC167",
    "rejected": "#CF5C5C",
    "cancelled": "#E2DB34",
    "pending": "#EE994A",
    "approved": "#013220"
  }


  const handlePayment = async (id: string) => {
    try {
      setPayLoader(true);
      await payChaperone(id);
      addNotification({
        type: 'success',
        title: 'Payment Successful'
      });
      refetch();
      setPayLoader(false);
    } finally {
      setPayLoader(false);
    }
  }

  useEffect(() => {
    refetch();
    close();
  }, [clearFilter])

  useEffect(() => {
    refetch();
  }, [page])

  return (
    <>
      {isLoading || isFetching || loading ? <LoaderForAll /> :
        <ContentLayout title="">
          <div className="bg-white rounded">
            <div className="row p-4">
              <div className="col-6 md-6">
                <h5 className='bold f-22'>Transactions</h5>
              </div>
              <div className="col-6 md-6 d-flex justify-content-end align-items-center">
                <Button
                  variant='primary'
                  className='px-4'
                  onClick={open}
                >
                  Filters
                </Button>
              </div>
            </div>

            <div className="row mt-3 transaction-tbl">
              <Table<TransactionDetails>
                page={page}
                changePage={changePage}
                data={data?.data ?? []}
                total={data?.count}
                totalPages={data?.totalPages}
                columns={[
                  { title: 'Booking ID', field: 'bookingUId' },
                  {
                    title: 'User Name',
                    field: 'userName',
                    Cell({ entry: { userName, image } }) {
                      return (
                        <>
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              src={image ? image : placeHolderProfile}
                              style={{
                                width: '35px',
                                height: '35px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                                position: "relative",
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeHolderProfile;
                              }}
                              alt=""
                            />
                            <span>{userName}</span>
                          </div>
                        </>
                      )
                    }

                  },
                  { title: 'Service', field: 'serviceName' },
                  { title: 'Amount', field: 'amount',
                  Cell({entry:{ amount }}) {

                    return `$${amount}`
                  }
                 },
                  {
                    title: 'Payment Mode',
                    field: 'bookingUId',
                    Cell({ entry }) {

                      return 'Card'
                    }
                  },
                  {
                    title: 'Date of Payment',
                    field: 'createdAt',
                    Cell({ entry: { createdAt } }) {
                      const newDate = moment(createdAt).format('DD MMM YYYY')
                      return newDate;
                    }
                  },
                 
                
                ]}
              >

              </Table>
            </div>
          </div>

          <Drawer
            title='Filters'
            isOpen={isOpen}
            onClose={close}
            size='sm'
            renderFooter={() => (
              <>
                <div className="row">
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={applyLoad}
                      onClick={handleApplyFiter}
                    >
                      Apply
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      variant='primary'
                      className='px-4'
                      isLoading={clearLoad}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </>
            )}
          >
            <>
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    style={{ paddingRight: '0.75rem' }}
                    placeholder="Booking ID"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <select
                    name="service"
                    className="form-control mt-4"
                    style={{ paddingRight: '0.75rem' }}
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                  >
                    {services?.allServices.map((val, index) => {
                      return (
                        <option key={index} value={val._id}>
                          {val.serviceName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="date"
                    className="form-control"
                    style={{ paddingRight: '0.75rem' }}
                    placeholder="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </>
          </Drawer>
        </ContentLayout>
      }
    </>
  );
};

export default Payment;
